@use "styles/functions";
@use "styles/bootstrap/variables/colors";
@use "src/styles/bootstrap/variables/zIndex";
@use "src/styles/constants";

:global(#root) > *:has(.container) {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;
  background: colors.$background-blue;

  h1 {
    font-size: functions.toRem(24);
    font-style: normal;
    font-weight: 500;
    line-height: functions.toRem(32);
  }

  h2 {
    color: colors.$deep-blue;
    font-size: functions.toRem(16);
    font-style: normal;
    font-weight: 600;
    line-height: functions.toRem(22);
  }

  h3 {
    font-size: functions.toRem(16);
    font-style: normal;
    font-weight: 600;
    line-height: functions.toRem(24);
  }
}

.step {
  display: flex;
  padding: functions.toRem(32);
  flex-direction: column;
  gap: functions.toRem(40);

  width: functions.toRem(440);

  margin: functions.toRem(48);

  border-radius: 8px;
  border: 1px solid colors.$gray-03;
  background: colors.$white;
}

.step-content {
  display: flex;
  flex-direction: column;
  gap: functions.toRem(24);
  align-self: stretch;
}

.hidden {
  display: none;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: functions.toRem(16);
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: functions.toRem(16);

  > * {
    flex: 1;
  }
}

.form-label {
  font-weight: 400;
  font-size: functions.toRem(12);
  line-height: functions.toRem(18);
  align-items: center;
  display: flex;
  color: colors.$gray-01;
}

.separator {
  width: 100%;
  margin: 0;
  border-top: 1px solid colors.$gray-03;
}

.navigation {
  display: flex;
  align-items: center;
  gap: functions.toRem(16);

  > * {
    flex: 1;
  }
}

.name-preview-label {
  color: colors.$gray-01;
  font-size: functions.toRem(14);
}

.name-preview {
  font-weight: 600;
}

.question {
  font-size: functions.toRem(14);
}

.display-name-preview-label {
  font-size: functions.toRem(14);
}

.display-name-preview {
  color: colors.$brand-blue;
  font-size: functions.toRem(14);
}
