@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.write-for-us {
  svg {
    max-width: 100%;
  }

  &__container {
    max-width: functions.toRem(1160);
    padding: 0;
  }

  &__title {
    @include mixins.rg-40;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
  }

  &__bottom-56 {
    margin-bottom: functions.toRem(51);
  }

  &__bottom-40 {
    margin-bottom: functions.toRem(40);
  }

  &__sub-title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    margin-bottom: functions.toRem(20);
  }
  &__intro {
    background-image: url("../../assets/svg/write-for-us/intro-guidelines-layout.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: functions.toRem(72) 0 functions.toRem(56);
    position: relative;
    min-height: functions.toRem(516);
  }

  &__intro-container {
    max-width: functions.toRem(784);
  }

  &__intro-paragraph {
    margin-bottom: functions.toRem(32);
    text-align: center;
  }

  &__guidelines {
    padding: functions.toRem(50) 0 0;
  }

  &__submit-requirements {
    padding: functions.toRem(32);
    background-color: colors.$gentle-green;
    text-align: center;
    border-radius: functions.toRem(24);
  }
  &__submit-requirements-title {
    @include mixins.rg-24;
    color: colors.$verified-green;
    margin-bottom: functions.toRem(16);
  }

  &__guidelines-row {
    margin-top: functions.toRem(62);
    margin-bottom: functions.toRem(80);
  }
  &__guidelines-left {
    flex: 0 0 functions.toRem(380);
    max-width: functions.toRem(380);
    padding: 0;
    background-color: colors.$gray-03;
  }
  &__guidelines-right {
    padding: 0;
    margin-left: functions.toRem(24);
  }

  &__style-guidelines {
    background-image: url("../../assets/svg/write-for-us/style-guidelines.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: functions.toRem(80) 0;
    position: relative;
  }
  &__style-guidelines-img {
    position: absolute;
    top: functions.toRem(164);
    right: 5%;
  }
  &__style-guidelines-container {
    border-radius: functions.toRem(24);
    background-color: colors.$white;
    padding: functions.toRem(48);
  }

  &__style-guidelines-right {
    flex: 0 0 functions.toRem(700);
    max-width: functions.toRem(700);
    margin-left: functions.toRem(16);
  }

  &__style-guidelines-row {
    align-items: center;
    padding: functions.toRem(12) functions.toRem(16);
  }

  &__style-guidelines-content {
    margin: functions.toRem(32) 0 functions.toRem(32) functions.toRem(52);
  }

  &__submission-decline {
    margin-top: functions.toRem(72);
    margin-bottom: functions.toRem(72);
  }

  &__submission-decline-warning {
    @include mixins.bd-16;
    text-align: center;
    padding: functions.toRem(32) functions.toRem(24);
    background-color: rgba(255, 115, 157, 0.15);
    margin-top: functions.toRem(40);
    margin-bottom: functions.toRem(64);
    border-radius: functions.toRem(24);
  }

  &__submission-decline-row {
    margin-top: functions.toRem(56);
    margin-bottom: functions.toRem(56);
    align-items: center;
    justify-content: center;
  }

  &__submission-decline-right {
    margin-left: functions.toRem(24);
    flex: 0 0 functions.toRem(640);
    max-width: functions.toRem(640);
  }

  &__faq {
    padding: functions.toRem(56) 0;
  }

  &__submit-blog {
    padding: functions.toRem(80) 0 functions.toRem(114);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  &__submit-blog-img {
    position: absolute;
    bottom: functions.toRem(80);
    right: 0;
  }

  &__submit-blog-row {
    margin-bottom: functions.toRem(32);
  }

  &__submit-blog-left {
    padding: 0;
    max-width: functions.toRem(48);
    flex: 0 0 functions.toRem(48);
  }

  &__submit-blog-right {
    margin-top: functions.toRem(13);
    padding-left: functions.toRem(16);
    padding-right: 0;
    flex: 0 0 functions.toRem(780);
    max-width: functions.toRem(780);
  }

  &__submit-blog-content {
    margin-left: functions.toRem(36);
  }

  &__submit-blog-title {
    @include mixins.bd-16;
    margin-bottom: functions.toRem(13);
  }

  &__footer {
    background-color: colors.$deep-blue;
    padding: functions.toRem(77) 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__action {
    margin-top: functions.toRem(36);
    display: flex;
    align-items: center;

    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;

    &:focus,
    &:hover {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none !important;
    }
  }

  &__action-text {
    margin-left: functions.toRem(12);
  }
}
