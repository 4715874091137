@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/bootstrap/variables/colors";
@use "src/styles/bootstrap/variables/zIndex";

.error-message {
  position: fixed;
  z-index: zIndex.$zindex-tooltip + 2000;
  top: functions.toRem(96);
  left: 50%;
  transform: translateX(-50%);
  border: functions.toRem(1) solid colors.$error-red;
  background-color: colors.$background-red;
  padding: functions.toRem(16) functions.toRem(24);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: functions.toRem(5);

  &__content-right {
    max-width: functions.toRem(300);
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: colors.$error-red;

    flex: 0 0 80%;
    max-width: 80%;

    svg {
      margin-right: functions.toRem(11);
    }
  }
  &__content-left {
    flex: 0 0 functions.toRem(30);
    max-width: functions.toRem(30);
  }
}
