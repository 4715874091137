.message-notifications {
  display: inline-block;
  color: var(--white);
  background-color: var(--error-red);
  border-radius: 50%;
  font-size: 0.8rem;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  position: relative;
  top: -0.5rem;
}
