@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/breakpoints";
@use "src/styles/bootstrap/variables/colors";

.footer {
  padding: functions.toRem(28) 0 functions.toRem(24);
  background-color: colors.$background-blue;
  border-top: functions.toRem(1) solid colors.$gray-03;
  height: functions.toRem(112);
}
