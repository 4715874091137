@use "src/styles/functions";

.loading {
  width: functions.toRem(100);
  text-align: center;
  margin: functions.toRem(100) auto;

  &__spinner {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
