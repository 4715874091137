@use "src/styles/functions";

.logo-loading {
  position: relative;

  // assume font size is 16px
  min-width: functions.toRem(24);
  min-height: functions.toRem(24);
  max-width: functions.toRem(100);
  max-height: functions.toRem(100);
  aspect-ratio: 1 / 1;
}
