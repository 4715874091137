@use "./functions";

$navbarHeight: functions.toRem(56);
// phone view
$navbarHeightPhoneView: functions.toRem(56);
$bannerHeight: functions.toRem(54);
$bottomNavbarHeight: functions.toRem(75);
$clientPortalFooterHeight: functions.toRem(112);
// calendar layout
$calendarViewTypeControllerHeight: functions.toRem(64);
$calendarCalendarControlsHeight: functions.toRem(64);
// table
$weeklyCalendarRowHeight: functions.toRem(108);
$weeklyCalendarFirstColumnWidth: functions.toRem(54);

$weeklyCalendarBorderWidth: 1px;
$monthlyCalendarBorderWidth: 1px;

// $calendarBorderColor: var(--gray02);
$calendarBorderColor: rgba(189, 189, 189, 0.4);
$weeklyCalendarBorder: $weeklyCalendarBorderWidth solid $calendarBorderColor;
$monthlyCalendarBorder: $monthlyCalendarBorderWidth solid $calendarBorderColor;

// dashboard sidebar width
$sidebarWidthOpen: 15%;
$sidebarWidthClose: functions.toRem(47);
$subSidebarWidth: functions.toRem(156);
$settingsSidebarWidth: functions.toRem(156) + functions.toRem(48);
$settingsFooterHeight: functions.toRem(68);
$patientChartSidebarWidth: functions.toRem(240);
// $patientChartSidebarWidth: 15%;
