@use "src/styles/functions";

.orchid-pro-avatar {
  position: relative;
  max-width: functions.toRem(120);
  width: 100%;

  // maintain aspect ratio
  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &--phone-view {
    max-width: functions.toRem(58);
  }

  &__main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: functions.toRem(1) solid var(--white);

    // &::before {
    //   content: "";
    //   display: block;
    //   padding-bottom: 100%;
    // }
  }

  &__sub {
    position: absolute;
    width: 37.5%;
    height: 37.5%;
    max-width: 37.5%;
    max-height: 37.5%;

    border: functions.toRem(2) solid var(--white);
    bottom: 0;
    right: 0;

    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}
