@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/zIndex";
$stepNavHeight: functions.toRem(107);

.auth-button {
  &--phone {
    @include mixins.bd-15;
    border-radius: 0.5rem;
    padding: functions.toRem(14) functions.toRem(20);
  }
}

.auth-form {
  &--phone {
    & :global(.form-control) {
      padding: functions.toRem(15) functions.toRem(16);
      border: functions.toRem(1) solid #e1e1e1;
      border-radius: functions.toRem(8);
      height: functions.toRem(52);
    }
  }
}

.password {
  & :global(.form-control) {
    border-radius: 0.25rem !important;
  }

  &:global(.input-group)
    :global(.input-group-append)
    :global(.input-group-text) {
    border: none !important;
    padding: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    background-color: transparent;
  }

  &:global(.input-group) :global(.input-group-append) {
    position: absolute;
    right: 0;
    z-index: 100;
  }

  & :global(.form-control.is-invalid),
  & :global(.form-control:invalid) {
    background-image: none;
  }

  & :global(.form-control.is-valid),
  & :global(.form-control:valid) {
    background-image: none;
  }
  &--phone {
    & :global(.form-control) {
      border-radius: 0.5rem !important;
    }
  }
}
