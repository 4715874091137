@use "src/styles/constants";
@use "src/styles/functions";
@use "src/styles/bootstrap/variables/zIndex";
@use "src/styles/bootstrap/variables/colors";
@use "src/styles/mixins";

.nav-bar {
  /* control height */
  height: constants.$navbarHeight;
  flex: none;
  z-index: zIndex.$zindex-fixed;
  padding: functions.toRem(16) functions.toRem(20);

  &__left {
    padding-left: functions.toRem(50);
    white-space: nowrap;

    &:global(.navbar-nav) :global(.nav-link.active) {
      border-bottom: functions.toRem(2) solid colors.$primary;
      font-weight: 600;
      padding: functions.toRem(16) 0;
    }

    &:global(.navbar-nav) :global(.nav-link) {
      padding: functions.toRem(16) 0;
      border-bottom: functions.toRem(2) solid transparent;
      &:not(:last-child) {
        margin-right: functions.toRem(32);
      }
    }
  }
  &__view-minor {
    @include mixins.rg-14;
    color: colors.$deep-orange;
  }
}

// @media (max-width: 1150px) {
//   .nav-bar {
//     padding: 0;

//     & :global(.navbar-brand) {
//       justify-content: center;
//       width: 100%;
//       height: constants.$navbarHeight;
//     }
//     & :global(.navbar-toggler) {
//       display: block;
//       position: absolute;
//       right: functions.toRem(20);
//       z-index: zIndex.$zindex-tooltip;
//     }
//   }
// }

// @media (max-width: 1250px) {
//   .nav-bar {
//     &__view-minor {
//       max-width: functions.toRem(250);
//     }
//   }
// }
