@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";
@use "src/styles/bootstrap/variables/grid";

$breakpoint: map-get(grid.$grid-breakpoints, "md");

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;

  &--text-white {
    color: colors.$white;
  }

  &--footer {
    background-color: colors.$text-black;
    padding: functions.toRem(72) functions.toRem(20);
    flex: 1 0 functions.toRem(346);
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &--footer-container {
    flex-direction: column;
    gap: functions.toRem(48);
    flex: 0 1 functions.toRem(1400);
    max-width: 100%;

    @media (max-width: $breakpoint) {
      gap: functions.toRem(32);
    }
  }

  &--footer-columns-row-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: functions.toRem(32) functions.toRem(32);
    flex-wrap: wrap;
  }

  &--footer-columns-row-mid {
    flex: 0 0 1px;
    width: 100%;
    background-color: colors.$gray-01;
  }

  &--footer-columns-row {
    display: flex;
    justify-content: space-between;
  }

  &--footer-columns-row-bottom {
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;

    &--phone {
      flex-direction: column;
    }

    &__right {
      @include mixins.rg-12;
      min-width: functions.toRem(668);

      color: colors.$gray-02;

      a,
      a:hover,
      a:visited,
      a:active,
      a:focus {
        color: #a3c5ff;
        text-decoration: none;
      }

      &--phone {
        min-width: 0;
        flex-direction: column;
        margin-top: functions.toRem(16);
      }
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1 1 0;
  max-width: 100%;

  &--footer-col-right {
    justify-content: flex-start;
    flex-direction: row;
    gap: functions.toRem(32) functions.toRem(24);
    flex: 0 1 functions.toRem(1440);
    flex-wrap: wrap;
  }

  &--footer-col-bottom {
    @include mixins.rg-11;

    flex: 0 1 0;
    width: auto;
    white-space: nowrap;
    padding-right: functions.toRem(6);
    color: colors.$gray-02;

    &:not(&--phone) + & {
      padding-left: functions.toRem(16);
      padding-right: functions.toRem(16);
      color: colors.$white;
    }

    &:not(&--phone) + &:not(:last-child) {
      border-right: 1px solid colors.$white;
    }

    &--phone {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }
  }
}

.footer-title {
  @include mixins.header-alt;
  color: colors.$brand-blue;
  text-align: left;
}

.footer-col {
  @include mixins.rg-14;

  display: flex;
  flex-direction: column;
  gap: functions.toRem(16);
  flex: 0 1 functions.toRem(202);
  min-width: functions.toRem(190);

  ul {
    padding-left: 0;
    list-style: none;

    li.hidden {
      display: none;
    }
  }
}

.footer-col-link {
  cursor: pointer;
  color: colors.$gray-02 !important;
  display: block;
}

.footer-col-text {
  display: flex;
  flex-direction: column;
  gap: functions.toRem(8);
}

.footer-bottom-col-link {
  color: colors.$white !important;
  display: block;
}

.paragraph {
  margin: 0;
}

.footer-col-with-hippa {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__up {
    flex: 1;
  }

  .footer-col-title {
    margin-bottom: functions.toRem(16);
  }
}

.footer-phone-line-row {
  margin-top: functions.toRem(10);
}

.footer-phone-line-col {
  padding: 0;
}

.adjust-width {
  width: 96%;
}

.footer__middle-col--phone {
  padding: 0;
}
