@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.container {
  padding: functions.toRem(56) functions.toRem(20);
}

.step-card {
  border-radius: functions.toRem(20);
  margin-bottom: functions.toRem(16);
  margin-top: functions.toRem(56);
}

.step-title {
  margin-bottom: functions.toRem(12);
  color: colors.$text-black;
  font-family: "Noto Sans";
  font-size: functions.toRem(24);
  font-weight: 600;
  line-height: functions.toRem(38);
}

.step-content {
  font-weight: 400;
  font-size: functions.toRem(16);
  line-height: functions.toRem(24);
}

.landing {
  background-color: colors.$primary;
  padding: 56px 20px 32px 20px;
  gap: functions.toRem(32);

  &__title {
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    letter-spacing: 0.8px;
    margin-bottom: functions.toRem(16);
    color: colors.$white;
  }

  &__content {
    font-weight: 400;
    font-size: functions.toRem(18);
    line-height: functions.toRem(28);
    color: colors.$white;
    margin-bottom: functions.toRem(16);
  }

  &__image {
    margin-bottom: functions.toRem(32);
  }
}

.case-study {
  background-color: colors.$background-blue;
  text-align: center;

  display: flex;
  padding: functions.toRem(56) functions.toRem(32);
  flex-direction: column;
  align-items: center;

  &__discover {
    color: colors.$gray-01;
    font-family: "Noto Sans";
    font-size: functions.toRem(14);
    line-height: functions.toRem(20);
    margin-bottom: functions.toRem(16);
  }

  &__case-study {
    color: colors.$deep-blue;
    font-family: "Noto Sans";
    font-size: functions.toRem(12);
    font-weight: 700;
    line-height: functions.toRem(32);
    margin-top: functions.toRem(32);
  }

  &__amhc {
    color: colors.$text-black;
    font-family: "Questrial";
    font-size: functions.toRem(23);
    line-height: functions.toRem(28);
  }

  &__learn {
    color: colors.$text-black;
    font-family: "Noto Sans";
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);
    margin: functions.toRem(24) 0;
  }
}

.download {
  width: 100%;
}

.quote-card {
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  padding: functions.toRem(32) functions.toRem(24);
  margin-top: functions.toRem(32);
  border-radius: functions.toRem(15);

  &__box {
    display: flex;
    flex-direction: column;
    gap: functions.toRem(32);
    text-align: left;
  }

  &__img {
    height: functions.toRem(197);
    border-radius: functions.toRem(8);
    margin-bottom: functions.toRem(32);
  }

  &__text {
    color: colors.$text-black;
    font-family: "Noto Serif";
    font-size: functions.toRem(18);
    line-height: functions.toRem(27);

    span {
      font-family: "Noto Serif";
      color: colors.$primary;
    }
  }

  &__citation {
    color: colors.$gray-01;
    font-family: "Noto Sans";
    font-size: functions.toRem(14);
    line-height: functions.toRem(21.6);
  }
}

.challenge {
  background-color: colors.$deep-blue;

  text-align: left;
  display: flex;
  padding: functions.toRem(56) functions.toRem(32);
  flex-direction: column;
  gap: functions.toRem(56);
  
  
  &__title {
    color: colors.$secondary-orange;
    font-family: "Questrial";
    font-size: functions.toRem(32);
    line-height: functions.toRem(42);
  }

  &__text {
    color: colors.$white;
    font-family: "Noto Sans";
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);
  }

  &__box {
    display: flex;
    flex-direction: column;
    padding: 0 functions.toRem(24);
    align-items: flex-start;
    gap: functions.toRem(16);
  }

  &__result-card {
    display: flex;
    flex-direction: column;
    padding: functions.toRem(32) functions.toRem(24);
    gap: functions.toRem(16);
    border-radius: functions.toRem(20);
    background: rgba(255, 255, 255, 0.20);
  }
}

.stat {
  padding: functions.toRem(32);
  margin-top: functions.toRem(16);
  text-align: center;
  border-radius: functions.toRem(12);
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.20);

  &__title {
    color: colors.$deep-orange;
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(38);
    letter-spacing: functions.toRem(3.2);
    padding-bottom: functions.toRem(10);
  }

  &__text {
    color: colors.$text-black;
    font-family: "Noto Sans";
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);
  }
}

.faq {
  padding: 56px 20px 16px 20px;

  &__title {
    color: colors.$deep-blue;
    font-family: "Questrial";
    font-size: functions.toRem(24);
    line-height: functions.toRem(38);
    text-align: center;
    margin-bottom: functions.toRem(16);
  }
  
  &__subtitle {
    color: colors.$text-black;
    font-family: "Noto Sans";
    font-size: functions.toRem(18);
    font-weight: 600;
    line-height: functions.toRem(28);
  }

  &__text {
    color: colors.$text-black;
    font-family: "Noto Sans";
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);

    a {
      text-decoration: underline;
    }
  }
}

.more-question {
  background-color: colors.$gray-04;
  padding: functions.toRem(32) functions.toRem(24);
  margin: 0px 20px 56px 20px;
  border-radius: functions.toRem(15);

  &__title {
    color: colors.$text-black;
    font-family: "Questrial";
    font-size: functions.toRem(24);
    line-height: functions.toRem(28);
  }

  &__action {
    width: 100%;
    margin-top: functions.toRem(24);
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: functions.toRem(48);
  width: 100%;
  padding: functions.toRem(8) functions.toRem(16);
  font-size: functions.toRem(16);

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.start-trial {
  font-weight: 400;
  font-size: functions.toRem(18);
  line-height: functions.toRem(28);
  margin-bottom: functions.toRem(48);
  text-align: center;
}

.title {
  font-family: "Questrial";
  font-weight: 400;
  font-size: functions.toRem(24);
  line-height: functions.toRem(34);
  color: colors.$deep-blue;
  text-align: center;
  margin-bottom: functions.toRem(16);
}

.get-power {
  padding: functions.toRem(56) functions.toRem(20);
  background-color: colors.$deep-blue;

  &__title {
    margin-bottom: functions.toRem(16);
    color: colors.$white;
    text-align: center;
    font-family: "Questrial";
    font-size: functions.toRem(27);
    font-weight: 400;
    line-height: functions.toRem(31);
  }

  &__text {
    font-weight: 400;
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);
    color: colors.$white;
    text-align: center;
    margin-bottom: functions.toRem(32);
  }
}
