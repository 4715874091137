@use "src/styles/functions";
@use "src/styles/bootstrap/variables/colors";

.form-control {
  font-size: 14px;
  line-height: 20px;
  border-color: colors.$secondary-blue;
  height: functions.toRem(40);
  &::-webkit-input-placeholder {
    color: colors.$gray-02;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &::placeholder {
    color: colors.$gray-02;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: colors.$gray-02;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: colors.$gray-02;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: colors.$secondary-blue;
  &:focus {
    border-color: colors.$primary;
    box-shadow: none;
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: colors.$text-black;
}

.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: colors.$gray-02;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label:before {
  border-color: colors.$primary;
  background-color: colors.$primary;
  box-shadow: none;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: colors.$white;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: colors.$white;
  box-shadow: none;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: colors.$text-black;
}
