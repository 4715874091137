@use "src/styles/bootstrap/variables/colors";
@use "src/styles/functions";

.table-of-contents {
  &--phone {
    position: sticky;
    top: 60px;
    left: 0;
    right: 0;

    z-index: 1;

    &--ignore-header {
      top: 4px;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s;
    }

    .mobile-header {
      display: flex;
      align-items: center;
      gap: 16px;

      padding: 17px 35px;
      width: 100%;

      background-color: white;

      font-size: functions.toRem(12);
      line-height: functions.toRem(18);

      &__label {
        font-weight: 600;
      }

      &__current-section {
        max-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: colors.$brand-blue;
      }
    }

    .list-container {
      height: 100%;
      background-color: transparentize(
        $color: colors.$text-black,
        $amount: 0.4
      );

      .title {
        padding-left: 16px;
        padding-right: 16px;

        margin-bottom: 12px;
      }

      &__content {
        padding-top: 20px;
        border-top: 1px solid colors.$gray-03;
        background-color: white;
      }
    }

    &--expanded {
      position: fixed;
      height: calc(100vh - 54px);
      overflow: scroll;
    }

    &--collapsed {
      box-shadow: 1px 2px 0 2px #ecececb2;
    }

    ul {
      li {
        padding-left: 16px;
        padding-right: 16px;

        .highlighted {
          a {
            color: colors.$brand-blue;
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 24px;

    font-size: functions.toRem(14);
    font-weight: 600;
    line-height: functions.toRem(28);

    text-transform: uppercase;
  }

  ul {
    padding: 0;

    li {
      list-style: none;

      padding-top: 8px;
      padding-bottom: 8px;

      &.highlighted {
        a {
          color: colors.$deep-orange;
        }
      }

      a {
        display: block;
        color: colors.$gray-01;
        text-decoration: none;
        font-size: functions.toRem(14);
        font-weight: 400;
        line-height: functions.toRem(24);
      }
    }
  }
}
