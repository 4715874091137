@use "src/styles/mixins";
@use "src/styles/functions";

.sticky-footer {
  @include mixins.phone-sticky-bottom;
  left: 0;
  border-top: functions.toRem(1) solid var(--gray03);
  background-color: white;
  display: inline-block !important;
  padding: 1rem;
}
