@use "./mixins";
/* refer to ui design's typography  */
/* e.g. 24px medium => md-24 */

.rg-8 {
  @include mixins.rg-8;
}

.rg-9 {
  @include mixins.rg-9;
}

.md-24 {
  @include mixins.md-24;
}

.rg-18 {
  @include mixins.rg-18;
}

.md-18 {
  @include mixins.md-18;
}

.bd-18 {
  @include mixins.bd-18;
}

.rg-24 {
  @include mixins.rg-24;
}

.rg-32 {
  @include mixins.rg-32;
}

.bd-32 {
  @include mixins.bd-32;
}

.rg-50 {
  @include mixins.rg-50;
}
.rg-40 {
  @include mixins.rg-40;
}

.bd-40 {
  @include mixins.bd-40;
}

.md-16 {
  @include mixins.md-16;
}

.md-14 {
  @include mixins.md-14;
}

.rg-16 {
  @include mixins.rg-16;
}

.bl-16 {
  @include mixins.bl-16;
}

.bd-16 {
  @include mixins.bd-16;
}

.rg-14 {
  @include mixins.rg-14;
}

.bd-14 {
  @include mixins.bd-14;
}

.rg-12 {
  @include mixins.rg-12;
}

.md-12 {
  @include mixins.md-12;
}

.bd-12 {
  @include mixins.bd-12;
}

.rg-11 {
  @include mixins.rg-11;
}

.rg-28 {
  @include mixins.rg-28;
}

.md-28 {
  @include mixins.md-28;
}

.rg-17 {
  @include mixins.rg-17;
}

.bd-17 {
  @include mixins.bd-17;
}

.bd-20 {
  @include mixins.bd-20;
}

.rg-15 {
  @include mixins.rg-15;
}

.rg-20 {
  @include mixins.rg-20;
}

.bd-15 {
  @include mixins.bd-15;
}

.rg-13-footnote {
  @include mixins.rg-13-footnote;
}
