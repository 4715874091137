@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/bootstrap/variables/colors";

.dropdown-toggle {
  &__title {
    @include mixins.rg-14;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  button {
    padding: functions.toRem(8) functions.toRem(12);
    height: 2.5rem;
  }
  &__caret {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: functions.toRem(24);
    height: functions.toRem(24);
  }

  & :global(.dropdown-menu) {
    width: 100%;
    box-shadow: 0px 2px 6px 0px rgba(189, 189, 189, 0.50);
    border: none;

    overflow-y: auto;
    max-height: 50vh;
    min-width: 100%;

  }

  & :global(.dropdown-item) {
    @include mixins.rg-14;
    &:hover {
      background-color: colors.$background-blue;
      color: colors.$text-black;
      cursor: pointer;
    }
    &:focus {
      color: colors.$white;
      background-color: colors.$primary;

      svg {
        stroke: colors.$white;
      }
    }
  }

  & :global(.btn):disabled {
    color: colors.$gray-01;
    border-color: colors.$gray-03;
  }
}
