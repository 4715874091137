@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/breakpoints";
@use "src/styles/bootstrap/variables/colors";

.book-demo {
  max-width: functions.toRem(355);

  @media (max-width: breakpoints.$mdBreakpoint) {
    max-width: unset;
  }

  &__form-group {
    margin: 0;
  }

  &__form-field {
    height: functions.toRem(48);
    margin: 0;
  }

  &-message {
    width: toRem(277);
    max-width: toRem(277);
  }

  &__form-elements {
    display: flex;
    flex-direction: column;
    gap: functions.toRem(8);
  }

  &-button {
    margin-top: functions.toRem(24);
    width: 100%;
    background-color: colors.$deep-orange;
    border: none;
    height: functions.toRem(48);
  }
}
