@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.phone-view {
  &__intro-paragraph {
    @include mixins.rg-14;
    margin-bottom: functions.toRem(16);
  }

  &__landing-section {
    position: relative;
    height: functions.toRem(540);
  }

  &__welcome-message {
    position: absolute;
    top: functions.toRem(198);
    background-color: colors.$background-blue;
    border-radius: functions.toRem(24);
    padding: functions.toRem(24);
    margin: 0 functions.toRem(20);
    box-shadow: -3px 5px 11px 0px rgba(130, 155, 198, 0.6);
  }

  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
  }
  &__sub-title {
    @include mixins.rg-18;
    color: colors.$deep-blue;
    font-family: "Questrial";
    margin-bottom: functions.toRem(16);
  }

  &__top-40 {
    margin-top: functions.toRem(40);
  }

  &__bottom-24 {
    margin-bottom: functions.toRem(24);
  }

  &__bottom-32 {
    margin-bottom: functions.toRem(32);
  }

  &__bottom-40 {
    margin-bottom: functions.toRem(40);
  }

  &__submit-requirements {
    padding: functions.toRem(24);
    background-color: colors.$gentle-green;
    border-radius: functions.toRem(24);
  }
  &__submit-requirements-title {
    @include mixins.rg-16;
    color: colors.$verified-green;
    margin-bottom: functions.toRem(16);
  }

  &__style-guidelines {
    background-image: url("../../assets/svg/write-for-us/style-guidelines-phone.svg");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: functions.toRem(60) functions.toRem(20) functions.toRem(40)
      functions.toRem(20);
    position: relative;
  }
  &__style-guidelines-inner {
    @include mixins.rg-14;
    border-radius: functions.toRem(12);
    background-color: colors.$white;
    padding: functions.toRem(32) functions.toRem(20);
  }
  &__style-guidelines-img {
    width: functions.toRem(56);
    height: functions.toRem(56);
    position: absolute;
    top: functions.toRem(32);
    right: functions.toRem(57);
  }

  &__style-guidelines-row {
    width: 100%;
    margin-bottom: functions.toRem(24);

    svg {
      flex: 0 0 functions.toRem(24);
      max-width: functions.toRem(24);
    }

    span {
      flex: 0 0 calc(100% - #{functions.toRem(24)});
      max-width: calc(100% - #{functions.toRem(24)});
      padding-left: functions.toRem(16);
    }
  }

  &__submission-decline-warning {
    @include mixins.bd-14;
    padding: functions.toRem(24);
    background-color: rgba(255, 115, 157, 0.15);
    margin-top: functions.toRem(24);
    margin-bottom: functions.toRem(32);
    border-radius: functions.toRem(24);
    text-align: center;
  }

  &__submission-decline {
    text-align: center;
  }

  &__container {
    padding: 0 functions.toRem(20);
    @include mixins.rg-14;
  }

  &__faq {
    padding: functions.toRem(40) 0 functions.toRem(32) 0;
  }

  &__submit-blog-img {
    max-width: functions.toRem(270);
  }

  &__submit-blog-row {
    margin-bottom: functions.toRem(32);
  }

  &__submit-blog-left {
    padding: 0;
    max-width: functions.toRem(32);
    flex: 0 0 functions.toRem(32);
  }
  &__submit-blog-right {
    padding-left: functions.toRem(16);
    padding-right: 0;
  }
  &__submit-blog-title {
    @include mixins.bd-14;
    margin-bottom: functions.toRem(13);
  }

  &__footer {
    background-color: colors.$deep-blue;
    padding: functions.toRem(40) functions.toRem(24);
  }
  &__action {
    margin-top: functions.toRem(36);
    display: flex;
    align-items: center;

    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;

    &:focus,
    &:hover {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none !important;
    }
  }

  &__action-text {
    margin-left: functions.toRem(12);
  }

  & :global(.card-header) {
    @include mixins.md-14;
    padding: functions.toRem(16) functions.toRem(16) functions.toRem(16)
      functions.toRem(20);
    height: auto;
  }

  & :global(.card-body) {
    @include mixins.rg-14;
    padding: functions.toRem(16) functions.toRem(20);
  }
}
