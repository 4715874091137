.layout {
  width: 100%;
  // height: 100vh;
  // overflow-x: hidden;
}

.body--phone {
  // intercom
  :global(.intercom-launcher),
  :global(.intercom-launcher-frame) {
    @keyframes intercom-launcher--phone {
      from {
        opacity: 0;
        transform: scale(0.5);
      }
      to {
        opacity: 1;
        transform: scale(0.8);
      }
    }
    transform: scale(0.8) !important;
    // 64px/bottom navigation height + 8px/spacing  + (60px/intercom default size - 48px/custom size) / 2
    bottom: 66px !important;
    animation: intercom-launcher--phone 250ms ease !important;
  }

  :global(.intercom-launcher-badge),
  :global(.intercom-launcher-badge-frame) {
    transform: scale(0.8) !important;
    bottom: 100px !important;
    right: 20px !important;
  }
}
