@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/constants";

$borderRadius: functions.toRem(14);

.appointment-checkout-container {
  max-width: functions.toRem(480);
  min-height: calc(100vh - #{constants.$navbarHeight});
  display: flex;
  align-items: center;
  &--phone {
    max-width: none;
  }
}

.appointment-book-modal {
  &__header {
    padding: functions.toRem(24);
    &--phone {
      padding: functions.toRem(16);
    }
  }

  &__body {
    padding: 0;

    &--phone {
      padding: functions.toRem(16) !important;
      overflow-y: auto;
    }
  }

  &--phone {
    padding: 0 !important;
    & :global(.modal-dialog) {
      margin: 0;
    }
    & :global(.modal-content) {
      height: 100vh;
    }
  }
}
