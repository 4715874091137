@use "src/styles/functions";

.modal-layout {
  &--xl {
    > :global(.modal-dialog) > :global(.modal-content) {
      border-radius: functions.toRem(12);
    }
  }

  > :global(.modal-dialog) > :global(.modal-content) {
    margin: auto;
  }

  &__scroll-container {
    max-height: 450px;
    overflow-y: auto;
  }
}

.footer {
  &:global(.modal-footer) {
    padding: functions.toRem(24);
    gap: functions.toRem(10);

    button {
      // override bootstrap
      margin: 0 !important;
      padding: functions.toRem(8) functions.toRem(16);
    }
  }
}
