@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.container {
  max-width: functions.toRem(1376);
  padding: 120px 0;
}

.title {
  @include mixins.rg-40;
  color: colors.$deep-blue;
  font-family: "Questrial";
  text-align: center;
  margin-bottom: 16px;
}

.bottom-36 {
  margin-bottom: functions.toRem(36);
}

.bottom-56 {
  margin-bottom: functions.toRem(56);
}

.bottom-40 {
  margin-bottom: functions.toRem(40);
}

.sub-title {
  @include mixins.rg-24;
  color: colors.$deep-blue;
  font-family: "Questrial";
  margin-bottom: functions.toRem(20);
}

.start-trial {
  margin-bottom: functions.toRem(100);
  text-align: center;
}

.i-love-ehr {
  padding: functions.toRem(100);
  background-color: #ffefef;
  text-align: center;

  &__container {
    max-width: 1140px;
  }

  &__title {
    @include mixins.rg-40;
    color: colors.$deep-orange;
    font-family: "Questrial";
  }
}

.step-row {
  margin-bottom: 72px;
  height: 422px;
}

.step-left {
  flex: 0 0 functions.toRem(652);
  max-width: functions.toRem(652);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.step-right {
  flex: 0 0 calc(100% - 652px);
  max-width: calc(100% - 652px);
  padding-left: 90px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.step-2-right {
  flex: 0 0 742px;
  max-width: 742px;
  padding-left: 90px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.step-2-left {
  flex: 0 0 calc(100% - 742px);
  max-width: calc(100% - 742px);
  padding-left: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.step-tag {
  color: colors.$deep-orange;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.step-title {
  color: colors.$text-black;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 8px;
}

.step-content {
  color: colors.$text-black;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  b {
    color: colors.$deep-blue;
  }
}

.slogan {
  padding: functions.toRem(64) functions.toRem(48);
  background-color: colors.$primary;
  margin-top: functions.toRem(56);
  margin-bottom: functions.toRem(120);
  border-radius: functions.toRem(24);

  &__title {
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    color: white;
    margin-bottom: 24px;
    text-align: center;
  }

  &__content {
    @include mixins.rg-16;
    text-align: center;
    color: white;
  }
}

.faq {
  padding: functions.toRem(56) 0;
  max-width: functions.toRem(1376);
}

.action {
  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;

  padding: 8px 16px;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.action-text {
  margin-left: functions.toRem(12);
}

.item {
  display: flex;

  padding: 0 12px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 8px;
  border: 1px solid colors.$gray-03;
  background-color: colors.$white;

  font-size: functions.toRem(14);
  line-height: functions.toRem(20);

  &__body {
    padding-top: 36px;
    text-align: left;
    flex-grow: 1;
    position: relative;
  }

  &__footer {
    display: flex;
    gap: functions.toRem(8);

    color: colors.$gray-01;

    font-size: functions.toRem(12);
    line-height: functions.toRem(18);
  }
}

.item-list {
  padding: 0;
  display: flex;
}

.quote-sign {
  position: absolute;
  left: 0;
  top: 0;

  color: colors.$primary;
  font-family: SeoulHangang CM;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 100% */
  letter-spacing: 1.2px;
}

.why-automate {
  background-color: colors.$deep-blue;
  padding: 120px 0 72px 0;
  text-align: center;

  &__container {
    max-width: 1140px;
    padding: 0;
  }

  &__title {
    color: white;

    /* Main Header */
    font-family: "Questrial";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px; /* 117.5% */
    letter-spacing: 0.8px;
    margin-bottom: 56px;
  }

  &__col {
    display: flex;
    padding: 0 16px;

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:nth-child(3) {
      padding-right: 0;
    }

    &:nth-child(4) {
      padding-left: 0;
    }
  }

  &__card {
    border-radius: 20px;
    background-color: white;
    padding: 56px;
    margin-bottom: 48px;
    width: 100%;
  }

  &__card-text {
    color: colors.$text-black;
    text-align: center;
    margin-top: 16px;

    /* Header Alt */
    font-family: "Questrial";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 116.667% */
  }
}

.empower {
  background-color: colors.$background-blue;
  display: flex;
  height: 750px;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 672px;
    max-width: 672px;
  }

  &__right {
    flex: 0 0 calc(100% - 672px);
    max-width: calc(100% - 672px);
    padding-left: 32px;
  }

  &__title {
    font-family: "Questrial";
    color: colors.$deep-blue;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    margin-bottom: 16px;
  }

  &__text {
    @include mixins.rg-18;
  }
}

.more-question {
  padding: 64px 72px;
  background-color: colors.$gray-03;
  margin-top: functions.toRem(56);
  margin-bottom: functions.toRem(56);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include mixins.rg-40;
    font-family: "Questrial";
    color: colors.$deep-blue;
  }
}

.get-power {
  padding: 56px 24px;
  background-color: colors.$deep-blue;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: colors.$white;
  }

  &__title {
    @include mixins.rg-40;

    font-family: "Questrial";
    margin-bottom: 16px;
  }
}
