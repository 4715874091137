@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";
@use "src/styles/breakpoints";

.book-a-demo {
  background-color: colors.$background-blue;
  padding: functions.toRem(120) 0 0;

  @media (max-width: breakpoints.$tabletBreakpoint) {
    padding-top: functions.toRem(56);
  }

  &__description {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: functions.toRem(16);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: functions.toRem(1231);
    margin: auto;
    padding: 0 functions.toRem(20);

    @media (max-width: breakpoints.$tabletBreakpoint) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  svg {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }

  &__right {
    max-width: functions.toRem(355);
    width: 100%;

    @media (max-width: breakpoints.$tabletBreakpoint) {
      max-width: unset;
    }
  }

  &__title {
    @include mixins.rg-40;
    font-family: "Questrial";
    color: colors.$deep-blue;
    margin-bottom: 0;

    @media (max-width: breakpoints.$tabletBreakpoint) {
      @include mixins.rg-24;
      line-height: functions.toRem(38);
    }
  }

  &__content {
    @include mixins.rg-18;
    @media (max-width: breakpoints.$tabletBreakpoint) {
      margin-bottom: functions.toRem(32);
    }
  }

  &__banner--phone {
    margin-top: 52px;
  }
}
