@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/constants";

.bottom-navigation {
  @include mixins.phone-sticky-bottom;

  display: grid;
  align-items: center;
  justify-content: center;
  border-top: functions.toRem(1) solid var(--gray03);
  padding: functions.toRem(10) functions.toRem(13);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  height: constants.$bottomNavbarHeight;

  &--include-in-layout {
    position: static;
    bottom: unset;
  }
}
