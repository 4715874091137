@use "./colors";

// Tooltips

$tooltip-font-size: 12px !default;
// $tooltip-max-width: 200px !default;
// $tooltip-color: $white !default;
$tooltip-bg: colors.$text-black !default;
$tooltip-border-radius: 0.3125rem !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.625rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;
