@use "src/styles/functions";
@use "src/styles/bootstrap/variables/colors";
@use "src/styles/mixins";

a:hover {
  text-decoration: none;
}

.blog-card {
  border: none;
  border-radius: functions.toRem(8);
  width: 100%;

  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);

  &--phone {
    border-radius: functions.toRem(8);
    border: none;
    box-sizing: border-box;
    box-shadow: 2px 6px 13px 2px #e2e6ed;
    width: 100%;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    padding: functions.toRem(24);
  }

  .card-text {
    @include mixins.bd-18;
    margin-bottom: 0;
    color: colors.$text-black;
  }

  .tag-and-read-time {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag {
      padding: 4px 10px;
      margin-right: 4px;

      background-color: colors.$background-orange;
      border-radius: 4px;

      color: colors.$deep-orange;
      font-size: functions.toRem(11);
      line-height: functions.toRem(16);
    }

    .read-time {
      color: colors.$gray-01;
      font-size: functions.toRem(12);
      line-height: functions.toRem(18);
    }
  }

  .summary {
    @include mixins.rg-14;
    flex: 1;
    color: colors.$gray-01;
    margin-bottom: 0;
    min-height: functions.toRem(60);
  }

  .author-and-date {
    margin-top: functions.toRem(32);
    display: flex;
    align-items: center;
    gap: 8px;

    .author {
      font-weight: 500;
      font-size: functions.toRem(14);
      line-height: functions.toRem(20);
      color: colors.$text-black;
    }

    .author-date-separator {
      font-size: functions.toRem(14);
      line-height: functions.toRem(20);
      color: colors.$gray-01;
    }

    .date-published {
      font-size: functions.toRem(12);
      line-height: functions.toRem(18);
      color: colors.$gray-01;
    }
  }
}

.blog-card:hover {
  box-shadow: 2px 6px 20px 2px #a8b5ca;
}
