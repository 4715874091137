@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/bootstrap/variables/colors";

.indicator-container {
  display: flex;
  padding: functions.toRem(9);

  :hover {
    cursor: pointer;
  }
}

.custom-select__hr {
  margin: 0;
  border-top: 1px solid colors.$gray-03;
}

.group-heading-v2 {
  margin-top: -16px;
  border-top: 1px solid colors.$gray-03;
}
