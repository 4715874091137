@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/breakpoints";
@use "src/styles/bootstrap/variables/colors";

.custom-accordion {
  &__question {
    @include mixins.bd-18;
    margin-bottom: 0;
  }

  &__icon-wrapper {
    width: functions.toRem(25);
    display: flex;
    align-items: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: functions.toRem(24);
    & > p {
      margin: 0;
    }
  }

  &__card {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid colors.$gray-02;
    border-radius: 0;

    &-header {
      @include mixins.rg-18;
      padding: functions.toRem(40) functions.toRem(24);
      background-color: colors.$white;
      display: flex;
      justify-content: space-between;
      border: none;

      @media (max-width: breakpoints.$mdBreakpoint) {
        padding: functions.toRem(32) 0 functions.toRem(24);
        gap: functions.toRem(16);
      }

      &:hover {
        cursor: pointer;
      }

      &--phone {
        @include mixins.bd-16;
        background-color: colors.$white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: functions.toRem(32) 0 functions.toRem(32);
        gap: functions.toRem(16);
        border: none;
      }
    }

    &-body {
      padding: 0 functions.toRem(24) functions.toRem(40);

      &--phone {
        padding: 0 0 functions.toRem(32);
        margin-top: functions.toRem(-8);
      }
    }
  }

  @media (max-width: breakpoints.$mdBreakpoint) {
    &__accordion {
      padding: 0;
    }

    &__card {
      &-body {
        padding: 0 0 functions.toRem(16);
      }
    }
  }
}

@media (max-width: breakpoints.$mdBreakpoint) {
  .custom-accordion {
    padding: functions.toRem(48) 0;
  }
}
