@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/bootstrap/variables/colors";

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: var(--white);

  // the color will also change the icon
  color: var(--gray01);

  &--active {
    color: colors.$primary;
  }

  & > &__message {
    @include mixins.rg-11;
  }

  &__icon {
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;

    // validate the style, it has not show in the design, idk if this is needed or not
    // the style is based on mui badge https://material-ui.com/components/badges/
    > .bottom-nav-item__badge {
      min-width: functions.toRem(20);
      width: functions.toRem(20);
      height: functions.toRem(20);
      display: flex;
      z-index: 1;
      position: absolute;
      flex-wrap: wrap;
      font-size: 0.75rem;
      box-sizing: border-box;
      transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      align-items: center;
      line-height: 1;
      align-content: center;
      border-radius: 50%;
      flex-direction: row;
      justify-content: center;

      // right-top corner
      top: 0;
      right: 0;
      transform: scale(1) translate(50%, -50%);
      transform-origin: 100% 0%;
    }
  }
}
