@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.required-panel {
  &__form-label {
    @include mixins.rg-12;
    color: colors.$gray-01;
    margin-bottom: functions.toRem(4);
  }
}
