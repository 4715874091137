@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.container {
  max-width: functions.toRem(1376);
}

.title {
  @include mixins.rg-40;
  color: colors.$deep-blue;
  font-family: "Questrial";
  text-align: center;
  margin-bottom: 16px;
}

.top-120 {
  margin-top: 120px;
}

.bottom-56 {
  margin-bottom: functions.toRem(56);
}

.bottom-40 {
  margin-bottom: functions.toRem(40);
}

.slogan {
  padding: functions.toRem(64) functions.toRem(48);
  background-color: colors.$primary;
  margin-top: functions.toRem(56);
  margin-bottom: functions.toRem(56);
  border-radius: functions.toRem(24);

  &__title {
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    color: white;
    margin-bottom: 24px;
    text-align: center;
  }

  &__content {
    @include mixins.rg-16;
    text-align: center;
    color: white;
  }
}

.faq {
  padding: functions.toRem(50) 0 functions.toRem(56) 0;
}

.action {
  margin-top: functions.toRem(36);
  display: flex;
  align-items: center;

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;
  padding: 8px 16px;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.action-text {
  margin-left: functions.toRem(12);
}

.item {
  display: flex;

  padding: 0 12px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 8px;
  border: 1px solid colors.$gray-03;
  background-color: colors.$white;

  font-size: functions.toRem(14);
  line-height: functions.toRem(20);

  &__body {
    padding-top: 36px;
    text-align: left;
    flex-grow: 1;
    position: relative;
  }

  &__footer {
    display: flex;
    gap: functions.toRem(8);

    color: colors.$gray-01;

    font-size: functions.toRem(12);
    line-height: functions.toRem(18);
  }
}

.item-list {
  padding: 0;
  display: flex;
}

.quote-sign {
  position: absolute;
  left: 0;
  top: 0;

  color: colors.$primary;
  font-family: SeoulHangang CM;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 100% */
  letter-spacing: 1.2px;
}

.landing {
  background-color: colors.$background-blue;
  display: flex;
  justify-content: space-between;
  height: 750px;
  position: relative;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__title {
    color: colors.$deep-blue;
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    margin-bottom: 16px;
  }

  &__text {
    @include mixins.rg-18;
  }
}

.more-question {
  padding: 64px 72px;
  background-color: colors.$gray-03;
  margin-bottom: functions.toRem(56);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include mixins.rg-40;
    font-family: "Questrial";
    color: colors.$deep-blue;
  }
}

.commitment {
  background-color: colors.$deep-blue;
  padding: 56px 90px;
  border-radius: 20px;
  margin-bottom: 32px;

  &__title {
    @include mixins.bd-40;
    color: colors.$white;
    margin-bottom: 32px;
  }

  &__content {
    @include mixins.rg-16;
    color: colors.$white;
  }
}

.list {
  &__title {
    @include mixins.rg-40;
    color: colors.$deep-blue;
    margin-bottom: 32px;
    font-family: "Questrial";
    text-align: center;
  }

  &__content {
    @include mixins.rg-18;
    text-align: center;
    margin-bottom: 56px;
  }
  &__col {
    display: flex;

    &:nth-child(odd) {
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }
  }
  &__card {
    box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
    border: none;
    padding: 56px 48px;
    margin-bottom: 32px;
    border-radius: 20px;
  }

  &__card-left {
    flex: 0 0 120px;
    max-width: 120px;
    padding: 0;
  }

  &__card-right {
    flex: 0 0 calc(100% - 120px);
    max-width: calc(100% - 120px);
    padding-left: 32px;
  }

  &__card-title {
    @include mixins.rg-24;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__card-content {
    @include mixins.rg-16;
  }
}
