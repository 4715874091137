@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.phone-view {
  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
  }

  & :global(.card-header) {
    @include mixins.md-14;
    padding: functions.toRem(32) 0;
    height: auto;
  }

  & :global(.card-body) {
    @include mixins.rg-14;
    padding: functions.toRem(16) 0;
  }
}

.faq {
  padding: 56px 20px;
}

.action {
  margin-top: functions.toRem(24);
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.save {
  background-color: colors.$deep-blue;
  padding: 56px 20px;

  &__title {
    @include mixins.rg-40;
    color: white;

    font-family: "Questrial";
    margin-bottom: 16px;
  }

  &__content {
    @include mixins.rg-16;
    color: white;
  }

  &__image {
    margin-top: 32px;
    margin-bottom: 8px;
  }

  &__star {
    color: colors.$deep-orange;
  }
}

.calculator {
  padding: 56px 20px;
  background-color: colors.$background-blue;

  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
    margin-bottom: 32px;
  }

  &__card {
    background-color: colors.$white;
    border-radius: 20px;
    padding: 48px 24px;
    box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  }

  &__row {
    align-items: center;
  }

  &__left {
    padding: 0;
    flex: 0 0 472px;
    max-width: 472px;
  }

  &__right {
    min-height: 522px;
    padding-left: 63px;
  }

  &__card {
    padding: 48px 24px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  }

  &__result {
    text-align: center;
  }

  &__text {
    @include mixins.bd-16;
    color: colors.$text-black;
    margin-bottom: 16px;
  }

  &__result-title {
    @include mixins.rg-24;
    font-family: "Questrial";
    color: colors.$primary;
  }

  &__result-text {
    @include mixins.rg-24;
    color: colors.$text-black;
    font-family: "Questrial";
    margin-bottom: 24px;
  }

  &__result-card {
    padding: 24px;
    background-color: colors.$background-blue;
    border-radius: 15px;
    margin-bottom: 32px;
  }

  &__small-text {
    @include mixins.rg-12;
    text-align: center;
    margin-top: 32px;
  }

  &__result-small-text {
    @include mixins.rg-16;
    color: colors.$gray-01;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &__result-card-number {
    @include mixins.rg-40;
    font-family: "Questrial";
    color: colors.$primary;
    margin-bottom: 12px;
  }

  &__result-sub-title {
    @include mixins.bd-18;
    color: colors.$text-black;
    margin-bottom: 12px;
  }

  &__saving-line {
    div {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  &__range-slider {
    // flex: 0 0 379px;
    // max-width: 379px;
    flex: 1;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__range-slider-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 24px;
  }

  &__zero {
    flex: 0 0 10px;
    max-width: 10px;
  }

  &__fifty {
    flex: 0 0 82px;
    max-width: 82px;
  }

  &__form-control {
    width: 82px;

    & :global(.input-group-text) {
      background-color: transparent;
      border-right: none;
    }

    & :global(.form-control) {
      border-left: none;
    }
  }

  &__hr {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &__only-when {
    @include mixins.rg-14;
    color: colors.$gray-01;
    margin-bottom: 8px;
  }

  &__action-group {
    width: 100%;

    button {
      width: 100%;
    }
  }

  &__action {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
    width: 100%;

    &:focus,
    &:hover {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none !important;
    }
  }
}
