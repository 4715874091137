@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.container {
  max-width: functions.toRem(1376);
}

.title {
  @include mixins.rg-40;
  color: colors.$deep-blue;
  font-family: "Questrial";
  text-align: center;
  margin-bottom: 16px;
}

.bottom-56 {
  margin-bottom: functions.toRem(56);
}

.calculator {
  padding: functions.toRem(100);
  background-color: colors.$background-blue;

  &__container {
    max-width: 1200px;
    padding: 0;
  }

  &__row {
    align-items: center;
  }

  &__left {
    padding: 0;
    flex: 0 0 472px;
    max-width: 472px;
  }

  &__right {
    min-height: 522px;
    padding-left: 63px;
  }

  &__card {
    padding: 64px 72px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  }

  &__result {
    text-align: center;
  }

  &__title {
    @include mixins.rg-40;
    color: colors.$deep-blue;
    font-family: "Questrial";
  }

  &__text {
    @include mixins.bd-18;
    color: colors.$text-black;
    margin-bottom: 16px;
  }

  &__result-title {
    @include mixins.rg-40;
    font-family: "Questrial";
    color: colors.$primary;
  }

  &__result-text {
    @include mixins.rg-32;
    color: colors.$text-black;
    font-family: "Questrial";
    margin-bottom: 32px;
  }

  &__result-card {
    padding: 32px;
    background-color: colors.$background-blue;
    border-radius: 15px;
    margin-bottom: 32px;
  }

  &__small-text {
    @include mixins.rg-16;
    color: colors.$gray-01;
    text-align: center;
    margin-top: 32px;
  }

  &__result-small-text {
    @include mixins.rg-16;
    color: colors.$gray-01;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  &__result-sub-title {
    @include mixins.rg-24;
    color: colors.$text-black;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  &__saving-line {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    span {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__range-slider {
    // flex: 0 0 379px;
    // max-width: 379px;
    flex: 1;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__range-slider-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 48px;
  }

  &__zero {
    flex: 0 0 10px;
    max-width: 10px;
  }

  &__fifty {
    flex: 0 0 82px;
    max-width: 82px;
  }

  &__form-control {
    width: 82px;

    & :global(.input-group-text) {
      background-color: transparent;
      border-right: none;
    }

    & :global(.form-control) {
      border-left: none;
    }
  }

  &__hr {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &__only-when {
    @include mixins.rg-16;
    color: colors.$gray-01;
    margin-bottom: 0;
  }

  &__action-group {
    width: 100%;

    button {
      width: calc(50% - 8px);
    }
  }

  &__action {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;

    &:focus,
    &:hover {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active {
      border-color: colors.$deep-orange;
      background-color: colors.$deep-orange;
      box-shadow: none !important;
    }
  }
}

.faq {
  padding: functions.toRem(120) 0;
}

.landing {
  background-color: colors.$deep-blue;
  display: flex;
  justify-content: space-between;
  height: 750px;
  position: relative;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__title {
    color: colors.$white;
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    margin-bottom: 16px;
  }

  &__text {
    @include mixins.rg-18;
    color: white;
  }

  &__star {
    color: colors.$deep-orange;
  }
}
