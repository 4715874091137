@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.wrapper {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.otp {
  display: flex;
  gap: 1rem;
  justify-content: center;

  input {
    display: flex;
    width: 3rem;
    height: 3.5rem;
    padding: 0.625rem 0.5rem;

    border-radius: 5px;
    transition: border-color 0.2s, box-shadow 0.2s;

    overflow: hidden;
    color: var(--Deep-blue, #185888);
    text-align: center;
    text-overflow: ellipsis;
    font-family: "Noto Sans";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 80% */
    border-color: colors.$gray-02;

    &:focus {
      border-color: colors.$primary !important;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  &.hasError input {
    border-color: var(--error-red-1100, #fc0d1b) !important;
  }
}

.error {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  color: var(--error-red-1100, #fc0d1b);
  // text-align: center;

  /* Caption */
  font-family: "Noto Sans";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 150% */

  svg {
    min-width: 1rem;
  }
}
