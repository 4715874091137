@use "../variables/colors";
// Buttons
.btn {
  overflow: hidden;
  text-overflow: ellipsis;
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: colors.$white;
    background-color: colors.$gray-02;
    border-color: colors.$gray-02;
    pointer-events: none;
  }

  &-primary {
    border-color: colors.$primary;

    &:hover {
      border-color: colors.$deep-blue;
      background-color: colors.$deep-blue;
    }
  }

  &-outline-primary {
    border-color: colors.$gray-02;
    background-color: colors.$white;

    &:disabled {
      color: colors.$gray-02;
      border-color: colors.$gray-02;
      background-color: colors.$white;
    }

    &:not(:disabled):not(.disabled):active {
      color: colors.$white;
    }
  }

  &-secondary {
    &:not(:disabled) {
      color: colors.$deep-blue !important;
    }

    &:focus {
      border-color: colors.$primary;
    }

    &:hover,
    &:focus {
      background-color: colors.$secondary-blue;
      border-color: colors.$secondary-blue;
    }
  }

  &-link {
    border-width: 0;
    // padding: 0;

    &:focus {
      box-shadow: 0 0 2px 2px transparent;
    }

    &:disabled {
      color: colors.$gray-02;
      background-color: inherit;
    }
  }
}
