@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.faq {
  padding: 120px 0 0 0;
}

.faq__container {
  max-width: functions.toRem(1376);
}

.why-automate {
  &__container {
    max-width: functions.toRem(1376);
    padding: 120px 0;
  }

  &__step-row {
    margin-bottom: 72px;
    height: 422px;
  }

  &__step-left {
    flex: 0 0 functions.toRem(652);
    max-width: functions.toRem(652);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
  }

  &__step-right {
    flex: 0 0 calc(100% - 652px);
    max-width: calc(100% - 652px);
    padding-left: 90px;
    padding-right: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
  }

  &__step-2-right {
    flex: 0 0 742px;
    max-width: 742px;
    padding-left: 90px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
  }

  &__step-2-left {
    flex: 0 0 calc(100% - 742px);
    max-width: calc(100% - 742px);
    padding-left: 90px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
  }

  &__step-title {
    color: colors.$text-black;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 16px;
  }

  &__step-content {
    @include mixins.rg-16;
    color: colors.$text-black;
    margin-bottom: 16px;
  }
}

.title {
  @include mixins.rg-40;
  color: colors.$deep-blue;
  font-family: "Questrial";
  text-align: center;
  margin-bottom: 80px;
}

.bottom-56 {
  margin-bottom: functions.toRem(56);
}

.bottom-40 {
  margin-bottom: functions.toRem(40);
}

.sub-title {
  @include mixins.rg-24;
  color: colors.$deep-blue;
  font-family: "Questrial";
  margin-bottom: functions.toRem(20);
}

.embrace-section {
  padding: functions.toRem(100);
  background-color: colors.$background-blue;
  text-align: center;

  &__container {
    max-width: 1140px;
    padding: 0;
  }

  &__title {
    @include mixins.bd-18;
    color: colors.$text-black;
  }

  &__sub-title {
    @include mixins.rg-16;
    margin-bottom: 56px;
  }
}

.more-question {
  padding: 64px 72px;
  background-color: colors.$gray-03;
  margin-top: functions.toRem(32);
  margin-bottom: functions.toRem(80);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include mixins.rg-40;
    font-family: "Questrial";
    color: colors.$deep-blue;
  }
}

.action {
  margin-top: functions.toRem(32);
  display: flex;
  align-items: center;

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;
  padding: 8px 16px;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.action-text {
  margin-left: functions.toRem(12);
}

.item {
  display: flex;

  padding: 0 12px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 8px;
  border: 1px solid colors.$gray-03;
  background-color: colors.$white;

  font-size: functions.toRem(14);
  line-height: functions.toRem(20);

  &__body {
    padding-top: 36px;
    text-align: left;
    flex-grow: 1;
    position: relative;
  }

  &__footer {
    display: flex;
    gap: functions.toRem(8);

    color: colors.$gray-01;

    font-size: functions.toRem(12);
    line-height: functions.toRem(18);
  }
}

.item-list {
  padding: 0;
  display: flex;
}

.quote-sign {
  position: absolute;
  left: 0;
  top: 0;

  color: colors.$primary;
  font-family: SeoulHangang CM;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 100% */
  letter-spacing: 1.2px;
}

.landing {
  background-color: #ffefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 750px;

  &__container {
    max-width: 1376px;
  }

  &__left {
    flex: 0 0 672px;
    max-width: 672px;
  }

  &__right {
    flex: 0 0 calc(100% - 672px);
    max-width: calc(100% - 672px);
    padding-left: 90px;
    padding-right: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    color: colors.$deep-blue;
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    margin-bottom: 16px;
  }

  &__text {
    @include mixins.rg-18;
    color: colors.$deep-blue;
  }
}

.get-power {
  padding: 56px 24px;
  background-color: colors.$deep-blue;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: colors.$white;
  }

  &__title {
    @include mixins.rg-40;

    font-family: "Questrial";
    margin-bottom: 16px;
  }
}

.how-ai-works {
  height: 2285px;
  padding: 120px 32px;
  position: relative;
  background-color: colors.$background-blue;
  background-image: url("../../assets/svg/red-line.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &__action {
    position: absolute;
    bottom: 120px;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
  }

  &__title {
    @include mixins.rg-40;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 1440px) {
  .how-ai-works {
    background-size: cover;
  }
}

.step-tag {
  color: colors.$deep-orange;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.step-title {
  color: colors.$text-black;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 16px;
}

.step-content {
  @include mixins.rg-16;
  color: colors.$text-black;
  margin-bottom: 0;
}

.step-1-card {
  position: absolute;
  padding: 48px 56px;
  top: 247px;
  left: calc(50% - 317px);
  border-radius: 20px;
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  width: 634px;
}

.step-2-card {
  position: absolute;
  padding: 48px 56px;
  top: 601px;
  left: calc(50% - 24px);
  border-radius: 20px;
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  width: 634px;
}

.step-3-card {
  position: absolute;
  padding: 48px 56px;
  top: 1009px;
  left: calc(50% - 317px);
  border-radius: 20px;
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  width: 634px;
}

.step-4-card {
  position: absolute;
  padding: 48px 56px;
  top: 1389px;
  left: calc(50% - 610px);
  border-radius: 20px;
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  width: 634px;
}

.step-5-card {
  position: absolute;
  padding: 48px 56px;
  top: 1717px;
  left: calc(50% - 317px);
  border-radius: 20px;
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  width: 634px;
}

.posts {
  background-color: colors.$background-blue;

  &__container {
    max-width: 1376px;
    background-color: colors.$background-blue;
    padding: 100px 0 120px 0;
  }

  &__title {
    @include mixins.rg-40;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
    margin-bottom: 16px;
  }

  &__content {
    @include mixins.rg-16;
    color: colors.$text-black;
    margin-bottom: 56px;
    text-align: center;
  }
}
