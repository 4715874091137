@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.container {
  max-width: functions.toRem(1376);
  display: flex;
}

.landing {
  background-color: colors.$primary;
  display: flex;
  height: functions.toRem(750);
  padding: functions.toRem(120) functions.toRem(32);

  &__intro-text {
    display: flex;
    flex-direction: column;
    gap: functions.toRem(32);
    justify-content: center;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 functions.toRem(695);
    max-width: functions.toRem(695);
  }

  &__right {
    flex: 0 0 calc(100% - 695px);
  }

  &__title {
    color: colors.$white;
    font-family: "Questrial";
    font-size: functions.toRem(50);
    line-height: functions.toRem(62);
    letter-spacing: functions.toRem(2);
    margin-bottom: functions.toRem(16);
  }

  &__text {
    color: colors.$white;
    font-size: functions.toRem(18);
    line-height: functions.toRem(28);
    margin-bottom: functions.toRem(36);
  }

  &__button {
    display: flex;
  }
}

.benefits {
  padding: 120px 32px 8px 32px;

  &__title {
    color: colors.$deep-blue;
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    text-align: center;
    margin-bottom: functions.toRem(16);
  }

  &__text {
    margin-bottom: functions.toRem(80);
    text-align: center;
  }

  &__row {
    margin-bottom: functions.toRem(72);
    height: functions.toRem(422);
  }
}

.benefit-row {
  margin-bottom: functions.toRem(72);
  height: functions.toRem(422);

  &__left-img {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right-text {
    flex: 0 0 calc(100% - 652px);
    padding-left: functions.toRem(90);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right-img {
    padding-left: functions.toRem(90);
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__left-text {
    flex: 0 0 calc(100% - 742px);
    padding-left: functions.toRem(90);
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    color: colors.$text-black;
    font-size: functions.toRem(30);
    font-weight: 600;
    line-height: functions.toRem(54);
    margin-bottom: functions.toRem(8);
  }

  &__content {
    color: colors.$text-black;
    font-size: functions.toRem(16);
    line-height: functions.toRem(26);
  }
}

.case-study {
  background-color: colors.$background-blue;
  text-align: center;

  display: flex;
  padding: functions.toRem(120) functions.toRem(32);
  flex-direction: column;
  align-items: center;
  gap: functions.toRem(80);

  &__container {
    max-width: functions.toRem(1140);
    padding: 0;
  }

  &__discover {
    color: colors.$gray-01;
    font-family: "Noto Sans";
    font-size: functions.toRem(24);
    font-weight: 400;
    line-height: functions.toRem(36);
  }

  &__case-study {
    color: colors.$deep-blue;
    text-align: center;
    font-family: "Noto Sans";
    font-size: functions.toRem(24);
    font-weight: 700;
    line-height: functions.toRem(32);
  }

  &__amhc {
    color: colors.$text-black;
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    letter-spacing: 0.8px;
  }

  &__learn {
    color: colors.$text-black;
    font-family: "Noto Sans";
    font-size: functions.toRem(18);
    line-height: functions.toRem(28);
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: functions.toRem(80);
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: functions.toRem(24);
    max-width: functions.toRem(500);
  }

  &__amhc-outer {
    display: flex;
    width: functions.toRem(908);
    flex-direction: column;
    align-items: center;
    gap: functions.toRem(32);
  }

  &__amhc-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: functions.toRem(16);
  }
}

.quote-card {
  height: functions.toRem(344);
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
  border: none;
  padding: functions.toRem(48);
  margin-bottom: functions.toRem(32);
  border-radius: functions.toRem(20);

  &__left {
    display: flex;
    max-width: functions.toRem(350);
    align-items: center;
    padding: 0;
  }

  &__right {
    display: flex;
    max-width: functions.toRem(1139);
    padding-left: functions.toRem(48);
    gap: functions.toRem(32);
  }

  &__img {
    border-radius: functions.toRem(8);
    width: functions.toRem(268);
    height: auto;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: functions.toRem(32);
    flex: 1 0 0;
  }

  &__text {
    text-align: left;
    color: colors.$text-black;
    font-family: "Noto Serif";
    font-size: functions.toRem(22);
    line-height: functions.toRem(37);

    span {
      font-family: "Noto Serif";
      color: colors.$primary;
    }
  }

  &__citation {
    color: colors.$gray-01;
    font-family: "Noto Sans";
    font-size: functions.toRem(20);
    line-height: functions.toRem(21.6);
  }
}

.challenge {
  text-align: left;
  display: flex;
  padding: functions.toRem(120) functions.toRem(32);
  flex-direction: column;
  align-items: center;
  gap: functions.toRem(70);
  background-color: colors.$deep-blue;

  &__title {
    color: colors.$secondary-orange;
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    letter-spacing: 0.8px;
  }

  &__text {
    color: colors.$white;
    font-family: "Noto Sans";
    font-size: functions.toRem(18);
    line-height: functions.toRem(28);
  }

  &__box {
    display: flex;
    flex-direction: column;
    width: functions.toRem(1139);
    padding: 0 functions.toRem(80);
    align-items: flex-start;
    gap: functions.toRem(16);
  }
}

.result-card {
  display: flex;
  width: functions.toRem(1139);
  padding: functions.toRem(64) functions.toRem(80);
  flex-direction: column;
  align-items: flex-start;
  gap: functions.toRem(32);
  border-radius: functions.toRem(20);
  background: rgba(255, 255, 255, 0.2);

  &__box {
    display: flex;
    flex-direction: column;
    width: functions.toRem(1059);
    align-items: flex-start;
    gap: functions.toRem(16);
  }
}

.stat {
  width: functions.toRem(290);
  height: functions.toRem(197);
  padding: functions.toRem(32);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: functions.toRem(16);
  border-radius: functions.toRem(12);
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);

  &__title {
    color: colors.$deep-orange;
    text-align: center;
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(38);
    letter-spacing: functions.toRem(3.2);
  }

  &__text {
    color: colors.$text-black;
    text-align: center;
    font-family: "Noto Sans";
    font-size: functions.toRem(16);
    line-height: 24px;
  }
}

.stats-container {
  display: flex;
  width: functions.toRem(979);
  flex-direction: column;
  align-items: flex-start;
  gap: functions.toRem(20);
}

.stats-col {
  display: flex;

  &:nth-child(odd) {
    justify-content: right;
    padding-right: 0;
  }

  &:nth-child(even) {
    justify-content: left;
    padding-left: 0;
  }
}

.stats-row {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  &:nth-child(odd) {
    justify-content: left;
  }

  &:nth-child(even) {
    justify-content: right;
  }
}

.faq {
  padding: 56px 32px 120px 32px;

  &__title {
    color: colors.$deep-blue;
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    text-align: center;
    margin-bottom: functions.toRem(56);
  }

  &__text {
    color: colors.$text-black;
    font-family: "Noto Sans";
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);

    a {
      text-decoration: underline;
      color: colors.$primary;
    }
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: functions.toRem(48);
  padding: functions.toRem(8) functions.toRem(16);

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.more-question {
  padding: functions.toRem(64) functions.toRem(72);
  background-color: colors.$gray-03;
  margin-top: functions.toRem(56);
  margin-bottom: functions.toRem(120);
  border-radius: functions.toRem(15);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    color: colors.$deep-blue;
  }
}

.get-power {
  padding: functions.toRem(56) functions.toRem(24);
  background-color: colors.$deep-blue;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: colors.$white;
  }

  &__title {
    font-family: "Questrial";
    font-size: functions.toRem(40);
    line-height: functions.toRem(47);
    margin-bottom: functions.toRem(16);
  }

  &__text {
    margin-bottom: functions.toRem(32);
  }
}

.email-modal__body {
  padding: functions.toRem(24);
}
