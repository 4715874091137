@use "src/styles/mixins";
@use "src/styles/functions";

/* bootstrap fix  */

.checkbox {
  $checkbox-height: functions.toRem(24);

  font-size: $checkbox-height;
  line-height: 1;

  &:global(.custom-checkbox) {
    // for box shadow
    margin-left: 3px;
    min-height: $checkbox-height;
  }

  :global(.custom-control-input) {
    width: $checkbox-height;
    height: $checkbox-height;
    z-index: 1;
  }

  :global(.custom-control-input):hover {
    cursor: pointer;
  }

  :global(.custom-control-label) {
    @include mixins.rg-14;
    line-height: $checkbox-height;
    padding-left: functions.toRem(10);
    cursor: pointer;
  }

  :global(.custom-control-label)::before {
    width: $checkbox-height;
    height: $checkbox-height;
    top: 0;
  }

  :global(.custom-control-label)::after {
    width: $checkbox-height;
    height: $checkbox-height;
    top: 0;
  }

  input[type="checkbox"] + .checkbox__label--circle:before {
    border-radius: 50%;
  }

  input[type="checkbox"] + .checkbox__label--rectangle:before {
    border-radius: functions.toRem(3);
  }

  &--sm {
    $checkbox-height: functions.toRem(16);
    &:global(.custom-checkbox) {
      font-size: $checkbox-height;
      min-height: $checkbox-height;
    }

    :global(.custom-control-input) {
      width: $checkbox-height;
      height: $checkbox-height;
    }

    :global(.custom-control-input):hover {
      cursor: pointer;
    }

    :global(.custom-control-label) {
      line-height: $checkbox-height;
      padding-left: 0;
    }

    :global(.custom-control-label)::before {
      width: $checkbox-height;
      height: $checkbox-height;
    }

    :global(.custom-control-label)::after {
      width: $checkbox-height;
      height: $checkbox-height;
    }
  }
}
