.input-group.has-validation > .custom-select:nth-last-child(2),
.input-group.has-validation > .form-control:nth-last-child(2) {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.was-validated .form-control:invalid + .input-group-append,
.form-control.is-invalid + .input-group-append {
  > .btn,
  > .input-group-text {
    border-color: var(--error-red);
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}
