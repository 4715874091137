@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.container {
  padding: functions.toRem(56) functions.toRem(20);
}

.step-section {
  padding: 32px 0;
}

.step-card {
  background-color: colors.$gray-03;
  border-radius: 20px;
  margin-bottom: 16px;
  height: 217px;
}

.step-tag {
  color: colors.$deep-orange;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.step-title {
  color: colors.$text-black;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.step-content {
  color: colors.$text-black;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 40px;
}

.why-switch {
  padding: functions.toRem(56) 0;
  background-color: colors.$deep-blue;
  flex-direction: column;
  display: flex;
  align-items: center;

  &__title {
    color: white;
    font-family: "Questrial";
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 162.963% */
    margin-bottom: 24px;
  }

  &__card {
    border-radius: 20px;
    padding: 36px 16px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 235px;
    max-width: 270px;
  }

  &__card-text {
    color: colors.$text-black;
    font-family: "Questrial";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 116.667% */
  }
}

.card {
  border-radius: 20px;
  margin-bottom: 32px;
  box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
}

.card-left {
  padding: 0;
  height: 286px;

  img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
}

.card-right {
  padding: 32px;
}

.card-title {
  color: colors.$text-black;

  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  margin-bottom: 16px;

  &__highlight {
    color: colors.$primary;
    padding: 0 2px;
  }
}

.bottom-32 {
  margin-bottom: 32px;
}

.bottom-24 {
  margin-bottom: functions.toRem(24);
}

.phone-view {
  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
  }

  &__faq {
    padding: 32px 20px;
  }

  & :global(.card-header) {
    @include mixins.md-14;
    padding: functions.toRem(16) functions.toRem(16) functions.toRem(16)
      functions.toRem(20);
    height: auto;
  }

  & :global(.card-body) {
    @include mixins.rg-14;
    padding: functions.toRem(16) functions.toRem(20);
  }
}

.i-love-ehr {
  padding: 32px 20px;
  background-color: #ffefef;
  text-align: center;
  font-family: "Questrial";

  &__title {
    color: colors.$deep-orange;
    text-align: center;

    font-family: "Questrial";
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
  }
}

.item {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  position: relative;

  border-radius: 8px;
  border: 1px solid colors.$gray-03;
  background-color: colors.$white;

  font-size: functions.toRem(14);
  line-height: functions.toRem(20);

  flex-basis: 100%;
  margin-bottom: 16px;

  &__body {
    padding-top: 36px;
    text-align: left;
  }

  &__footer {
    display: flex;
    gap: functions.toRem(8);

    color: colors.$gray-01;

    font-size: functions.toRem(12);
    line-height: functions.toRem(18);
  }
}

.quote-sign {
  position: absolute;
  left: 24px;
  top: 24px;

  color: colors.$primary;
  font-family: SeoulHangang CM;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 100% */
  letter-spacing: 1.2px;
}

.slogan {
  padding: 32px 24px;
  background-color: colors.$primary;
  margin: 32px 20px;
  border-radius: 20px;

  &__title {
    font-family: "Questrial";

    color: white;
    margin-bottom: 24px;

    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 131.25% */
  }

  &__content {
    @include mixins.rg-14;
    text-align: center;
    color: white;
    margin-bottom: 24px;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.switch {
  background-color: colors.$primary;
  padding: 56px 20px 32px 20px;

  &__title {
    color: white;

    font-family: "Questrial";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0.8px;
    margin-bottom: 16px;
  }

  &__content {
    @include mixins.rg-16;
    color: white;
  }

  &__image {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.start-trial {
  margin-bottom: functions.toRem(16);
  text-align: center;
}

.title {
  @include mixins.rg-24;
  color: colors.$deep-blue;
  font-family: "Questrial";
  text-align: center;
  margin-bottom: 16px;
}
