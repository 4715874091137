@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/bootstrap/variables/colors";

.modal-header {
  &:global(.modal-header) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: functions.toRem(24);
    z-index: 1;
  }

  &__title {
    @include mixins.header-alt;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: functions.toRem(8);

    font-size: functions.toRem(24);
    color: colors.$deep-blue;
    line-height: functions.toRem(28);
    margin: 0;
    padding: 0;
  }

  &__close {
    flex: 0 0 functions.toRem(24);
  }

  /* bootstrap fix  */
  > :global(.close) {
    padding: 5px;
  }

  > :global(.close) > span:first-child {
    width: 24px;
  }
}
