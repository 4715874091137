@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/zIndex";
@use "src/styles/bootstrap/variables/colors";

$stepNavHeight: functions.toRem(107);

.register-modal__header {
  &--phone {
    @include mixins.bd-17;
    height: constants.$navbarHeight;
    padding: functions.toRem(14) functions.toRem(15);
    border-bottom: functions.toRem(1) solid var(--gray03);
    display: flex;
    align-items: center;
    justify-content: center;

    // the second child, name
    & > *:nth-child(2) {
      margin-left: 0 !important;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // others
    & > *:not(:nth-child(2)) {
      flex: none;
    }
  }
}

.auth-button {
  &--phone {
    @include mixins.bd-15;
    border-radius: 0.5rem;
    padding: functions.toRem(14) functions.toRem(20);
  }
}

.auth-form {
  &--phone {
    & :global(.form-control) {
      padding: functions.toRem(15) functions.toRem(16);
      border: functions.toRem(1) solid #e1e1e1;
      border-radius: functions.toRem(8);
      height: functions.toRem(52);
    }
  }
}

.password {
  & :global(.form-control) {
    border-radius: 0.25rem !important;
  }

  &:global(.input-group)
    :global(.input-group-append)
    :global(.input-group-text) {
    border: none !important;
    padding: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    background-color: transparent;
  }

  &:global(.input-group) :global(.input-group-append) {
    position: absolute;
    right: 0;
    z-index: 100;
  }

  & :global(.form-control.is-invalid),
  & :global(.form-control:invalid) {
    background-image: none;
  }

  & :global(.form-control.is-valid),
  & :global(.form-control:valid) {
    background-image: none;
  }
  &--phone {
    & :global(.form-control) {
      border-radius: 0.5rem !important;
    }
  }
}

.welcome-container {
  height: calc(100vh - #{constants.$navbarHeight});
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: functions.toRem(430);

  &__body {
    text-align: center;
  }
}

.member-intake-form {
  &__header {
    background-color: colors.$background-blue;
    padding-top: functions.toRem(24);
    padding-bottom: functions.toRem(24);
    height: functions.toRem(107);
  }

  &__body {
    height: calc(100vh - 6.688rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content-required {
    width: functions.toRem(366);
  }
  &__content-optional {
    width: functions.toRem(561);
  }
}

.email-sent-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: functions.toRem(504);
}

.email-sent-img {
  height: functions.toRem(135);
  width: functions.toRem(139);
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: functions.toRem(24);
}

.get-started-member-img {
  height: auto;
  width: functions.toRem(552.07);
  margin-bottom: functions.toRem(24);
}

.get-started-orchid-pro-img {
  height: auto;
  width: functions.toRem(556.99);
  margin-bottom: functions.toRem(24);
}

.reset-password {
  height: calc(100vh - #{constants.$navbarHeight});
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$background-blue;

  &__container {
    border-radius: functions.toRem(8);
    min-width: functions.toRem(410);
    padding: functions.toRem(24);
    background-color: colors.$white;
  }

  &--phone {
    height: calc(100vh - #{constants.$navbarHeight});
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
  }
  &__title {
    @include mixins.rg-32;
    margin-bottom: functions.toRem(16);
    font-family: "Questrial";
    color: colors.$deep-blue;
    text-align: center;
  }

  &__form-label {
    @include mixins.rg-12;
    color: colors.$gray-01;
    margin-bottom: functions.toRem(4);
  }
}

.nav-bar {
  /* control height */
  height: constants.$navbarHeight;
  flex: none;
  z-index: 1000;
  padding: functions.toRem(16) functions.toRem(20);
}
