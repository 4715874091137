@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

$stepNavHeight: functions.toRem(107);

.intake-form {
  &__alert {
    background-color: colors.$background-blue;
    color: colors.$deep-blue;
    padding: functions.toRem(12) functions.toRem(16);
    margin-bottom: functions.toRem(16);
  }
  &--phone {
    margin-top: constants.$navbarHeightPhoneView;
    margin-bottom: functions.toRem(75);
  }

  &__header {
    background-color: colors.$background-blue;
    padding-top: functions.toRem(24);
    padding-bottom: functions.toRem(24);
    height: $stepNavHeight;
  }

  &__body {
    min-height: calc(100vh - #{$stepNavHeight});
    display: flex;
    align-items: center;
    justify-content: center;
    padding: functions.toRem(24) 0 functions.toRem(64) 0;
  }

  &__content-required {
    width: functions.toRem(366);
  }

  &__content-optional {
    width: functions.toRem(561);
  }

  &__step-nav {
    width: functions.toRem(41);
    height: functions.toRem(41);
    border: functions.toRem(1) solid colors.$primary;
    border-radius: 50% !important;
    display: flex;
    align-items: center;

    &--phone {
      width: 100%;
      height: 100%;
      background-color: #93c5fc;
      border-radius: 50% !important;
      padding: 0;
      border: 0;
    }
  }

  &__step-nav-item {
    @include mixins.rg-11;
    width: functions.toRem(41) + functions.toRem(146);
    text-align: center;
    padding: 0;
    margin-bottom: 0;

    &--phone {
      width: functions.toRem(12);
      height: functions.toRem(12);
      flex-grow: 0;

      &:first-of-type {
        margin-right: functions.toRem(10);
      }

      & :global(.nav-link) {
        padding: 0;
      }
    }
  }

  &__action--register {
    @include mixins.rg-14;
    width: functions.toRem(120);
    padding: functions.toRem(8) functions.toRem(12);
    margin-top: functions.toRem(30);
  }

  &__save--edit-profile {
    @include mixins.rg-14;
    width: functions.toRem(109);
    padding: functions.toRem(8) functions.toRem(12);
    margin-top: functions.toRem(30);
  }
}
