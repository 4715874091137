.lazy-image {
  max-width: 100%;
  max-height: 100%;

  &--rounded {
    border-radius: 50%;
  }

  &--fallback-grey {
    background-color: #b3b3b3;
  }

  &.lazy-image--loading-no-grey:global(.lazyloading),
  &--fallback {
    background: no-repeat center / 100px
      url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzgiIGhlaWdodD0iNzUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQxLjI3NCA3NC45NjloLS4wNTRhNDEuNzg2IDQxLjc4NiAwIDAxLTEwLjI1LTEuMjQ0bC0yMC4xMS4wNmgtLjAzMWExMC44MjkgMTAuODI5IDAgMDEtOS45NS0xNS4xMDNMNS43MjggNDcuMzlhNDYuNjg2IDQ2LjY4NiAwIDAxLTEuMjA0LTkuODcydi0uMTUzQTQyLjEyNSA0Mi4xMjUgMCAwMTkuMjEgMTguMjI5IDMzLjQ1OCAzMy40NTggMCAwMTIyLjEyNiA0LjgxMkMyNy42MjYgMS42MiAzNC4wNTkgMCA0MS4yNSAwaC4wNTVjNy4yMTYuMDA4IDEzLjY1MyAxLjY0NCAxOS4xMzEgNC44NjJBMzMuODEzIDMzLjgxMyAwIDAxNzMuMzEgMTguMzQgNDEuODc2IDQxLjg3NiAwIDAxNzggMzcuNDQ5di4xNTdhNDEuODY3IDQxLjg2NyAwIDAxLTQuNzM1IDE5LjA5OUEzMy44MDIgMzMuODAyIDAgMDE2MC4zNiA3MC4xNTJjLTUuNDcxIDMuMTk2LTExLjg5MiA0LjgxNy0xOS4wODYgNC44MTd6bS05Ljc4OS05LjM3MmMuMzU5IDAgLjcxNi4wNDcgMS4wNjIuMTRhMzMuNDQ3IDMzLjQ0NyAwIDAwOC42OCAxLjEwNWguMDQ3YzUuNzM0IDAgMTAuNzc2LTEuMjQ4IDE0Ljk4Ni0zLjcwOEEyNS40NSAyNS40NSAwIDAwNjYuMSA1Mi44N2EzMy4yNjggMzMuMjY4IDAgMDAuMDM0LTMwLjcxMSAyNS40NjIgMjUuNDYyIDAgMDAtOS44MTUtMTAuMjljLTQuMjE1LTIuNDc1LTkuMjctMy43MzQtMTUuMDI1LTMuNzRoLS4wNDRhMjkuNTU4IDI5LjU1OCAwIDAwLTE1LjA0MyAzLjcxMiAyNS4xMTIgMjUuMTEyIDAgMDAtOS44MjYgMTAuMjA0IDM0LjA2NiAzNC4wNjYgMCAwMC0zLjczMyAxNS40MDdjLjA0NSAzLjExLjQ3NCA2LjIwMyAxLjI3NSA5LjIwOGE0LjA2NCA0LjA2NCAwIDAxLS4xODcgMi42NzNsLTUuMzkzIDEyLjU2YTIuNzAxIDIuNzAxIDAgMDAyLjQ4MSAzLjc2OGguMDFsMjAuNjM5LS4wNjEuMDEyLS4wMDJ6IiBmaWxsPSIjMTc3OEUxIi8+PHBhdGggZD0iTTI4LjIyMyA0Mi4wMTJhMy4zMjUgMy4zMjUgMCAxMDAtNi42NSAzLjMyNSAzLjMyNSAwIDAwMCA2LjY1em0xMi44OTcgMGEzLjMyNSAzLjMyNSAwIDEwMC02LjY1IDMuMzI1IDMuMzI1IDAgMDAwIDYuNjV6bTEyLjAxNiAwYTMuMzI1IDMuMzI1IDAgMTAwLTYuNjUgMy4zMjUgMy4zMjUgMCAwMDAgNi42NXoiIGZpbGw9IiMxNzc4RTEiLz48L3N2Zz4=");
    object-fit: none !important;
    max-width: 100%;
    max-height: 100%;
  }

  &--error {
    opacity: 0;
  }
}
