@use "../variables/colors";

$border-color: colors.$secondary-blue;

%figma-selected {
  color: colors.$text-black;
  border-color: $border-color;

  svg {
    color: colors.$gray-01;
  }
}

%figma-active {
  color: colors.$text-black;
  border-color: colors.$primary;

  svg {
    color: colors.$primary;
  }
}

.dropdown-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  flex: 0 1 auto;
  box-shadow: none !important;
  // not used elsewhere
  color: colors.$text-black;
  border-color: $border-color;
  background-color: colors.$white !important;

  // exception
  &.btn-link {
    color: colors.$primary;

    &:hover {
      color: colors.$primary !important;
    }
  }

  svg {
    color: colors.$gray-01;
  }

  // TODO: sync w/ UX :hover state
  &:not(:disabled):not(.disabled):hover {
    @extend %figma-selected;
  }

  // figma active -> css :focus
  &:not(:disabled):not(.disabled):focus {
    @extend %figma-active;
  }

  // figma selected -> css :active
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > & {
    @extend %figma-selected;
  }

  &::after {
    display: none !important;
  }
}

// cycle all btn classes
$btn-classes: ".btn-outline-primary", ".btn-outline-secondary";

@each $class in $btn-classes {
  #{$class}.dropdown-toggle:not(:disabled):not(.disabled):active,
  #{$class}.dropdown-toggle:not(:disabled):not(.disabled).active,
  .show > #{$class}.dropdown-toggle {
    @extend %figma-selected;
  }
}
