$height: 95%;

.responsive-modal--phone-portrait {
  $root: &;

  &:global(.show.fade) &__dialog {
    transform: none;
  }

  &:global(.fade) &__dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, 100%);
  }

  & > &__dialog {
    width: 100%;
    height: $height;
    min-height: unset;
    max-height: unset;
    min-width: unset;
    max-width: unset;
    margin: 0;
    position: fixed;
    bottom: 0;

    // bootstrap override
    & > #{$root}__content {
      height: 100%;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
  }

  &--full-page {
    > #{$root}__dialog {
      width: 100%;
      height: 100%;

      > #{$root}__content {
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
      }
    }
  }
}
