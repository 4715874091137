@use "src/styles/functions";
@use "src/styles/bootstrap/variables/zIndex";
@use "src/styles/bootstrap/variables/colors";

/* refer to ui design's typography  */
/* e.g. 24px medium => md-24 */

$font-weight-bl: 700;
$font-weight-bd: 600;
$font-weight-md: 500;
$font-weight-rg: 400;
$font-questrial: "Questrial";

@mixin rg-8 {
  font-weight: $font-weight-rg;
  font-size: 8px;
  line-height: 18px;
}

@mixin rg-9 {
  font-weight: $font-weight-rg;
  font-size: 9px;
  line-height: 2;
}

@mixin bd-9 {
  font-weight: $font-weight-bd;
  font-size: 9px;
  line-height: 2;
}

@mixin rg-28 {
  font-weight: $font-weight-rg;
  font-size: 28px;
  line-height: 34px;
}

@mixin md-28 {
  font-weight: $font-weight-md;
  font-size: 28px;
  line-height: 42px;
}

@mixin rg-24 {
  font-weight: $font-weight-rg;
  font-size: 24px;
  line-height: 34px;
}

@mixin md-24 {
  font-weight: $font-weight-md;
  font-size: 24px;
  line-height: 32px;
}

@mixin rg-32 {
  font-weight: $font-weight-rg;
  font-size: 32px;
  line-height: 42px;
}

@mixin bd-32 {
  font-weight: $font-weight-bd;
  font-size: 32px;
  line-height: 20px;
}

@mixin rg-50 {
  font-weight: $font-weight-rg;
  font-size: 50px;
  line-height: 62px;
}

@mixin rg-40 {
  font-weight: $font-weight-rg;
  font-size: 40px;
  line-height: 47px;
}

@mixin rg-50 {
  font-weight: $font-weight-rg;
  font-size: 50px;
  line-height: 62px;
}

@mixin bd-40 {
  font-weight: $font-weight-bd;
  font-size: 40px;
  line-height: 62px;
}

%phone-18 {
  font-size: 18px;
  line-height: 28px;
}

%phone-20 {
  font-size: 20px;
  line-height: 24px;
}

@mixin rg-20 {
  @extend %phone-20;
  font-weight: $font-weight-rg;
}

@mixin bd-20 {
  @extend %phone-20;
  font-weight: $font-weight-bd;
}

@mixin rg-18 {
  font-weight: $font-weight-rg;
  @extend %phone-18;
}

@mixin rg-18-subheader {
  @extend %phone-18;
  font-weight: $font-weight-rg;
  line-height: 34px;
}

@mixin md-18 {
  font-weight: $font-weight-md;
  @extend %phone-18;
}

@mixin bd-18 {
  font-weight: $font-weight-bd;
  @extend %phone-18;
}

%phone-17 {
  font-size: 17px;
  line-height: 22px;
}

@mixin bd-17 {
  @extend %phone-17;
  font-weight: $font-weight-bd;
}

@mixin rg-17 {
  @extend %phone-17;
  font-weight: $font-weight-rg;
}

@mixin bl-16 {
  font-weight: $font-weight-bl;
  font-size: 16px;
  line-height: 20px;
}

@mixin md-16 {
  font-weight: $font-weight-md;
  font-size: 16px;
  line-height: 20px;
}

@mixin rg-16 {
  font-weight: $font-weight-rg;
  font-size: 16px;
  line-height: 24px;
}

@mixin bd-16 {
  font-weight: $font-weight-bd;
  font-size: 16px;
  line-height: 22px;
}

%phone-15 {
  font-size: 15px;
  line-height: 20px;
}

@mixin rg-15 {
  @extend %phone-15;
  font-weight: $font-weight-rg;
}

@mixin bd-15 {
  @extend %phone-15;
  font-weight: $font-weight-bd;
}

@mixin bl-14 {
  font-weight: $font-weight-bl;
  font-size: 14px;
  line-height: 20px;
}

@mixin bd-14 {
  font-weight: $font-weight-bd;
  font-size: 14px;
  line-height: 20px;
}

@mixin md-14 {
  font-weight: $font-weight-md;
  font-size: 14px;
  line-height: 20px;
}

@mixin rg-14 {
  font-weight: $font-weight-rg;
  font-size: 14px;
  line-height: 20px;
}

@mixin rg-13 {
  font-weight: $font-weight-rg;
  font-size: 13px;
  line-height: 18px;
}

@mixin rg-13-footnote {
  font-weight: $font-weight-rg;
  font-size: 13px;
  line-height: 16px;
}

@mixin rg-12 {
  font-weight: $font-weight-rg;
  font-size: 12px;
  line-height: 18px;
}

@mixin md-12 {
  @include rg-12;

  font-weight: $font-weight-md;
}

@mixin bd-12 {
  @include rg-12;

  font-weight: $font-weight-bd;
}

@mixin bl-12 {
  @include rg-12;

  font-weight: $font-weight-bl;
}

@mixin rg-11-caption {
  font-weight: $font-weight-rg;
  font-size: 11px;
  line-height: 13px;
}

@mixin rg-11 {
  font-weight: $font-weight-rg;
  font-size: 11px;
  line-height: 18px;
}

@mixin md-36 {
  font-weight: $font-weight-md;
  font-size: 36px;
  line-height: 32px;
}

// latest
@mixin caption-mobile {
  font-weight: $font-weight-rg;
  font-size: 11px;
  line-height: 16px;
}

%phone-sticky {
  position: fixed;
  z-index: zIndex.$zindex-sticky;
  background-color: var(--white);
  width: 100vw;
  display: flex;
}

@mixin phone-sticky-top {
  @extend %phone-sticky;
  box-shadow: 0 functions.toRem(1) functions.toRem(2) rgba(0, 0, 0, 0.1);
  top: 0;
}

@mixin phone-sticky-bottom {
  @extend %phone-sticky;
  bottom: 0;
}

// latest
@mixin main-header {
  @include rg-40;

  font-family: $font-questrial;
  letter-spacing: 0.02em;
}

@mixin main-header-alt {
  @include main-header();
  @include rg-32;

  line-height: 42px;
  letter-spacing: 0em;
}

@mixin header-alt {
  @include rg-24;

  line-height: 28px;
  font-family: $font-questrial;
  text-align: center;
}

//styleName: Big Header;
@mixin big-header {
  @include rg-50;

  font-family: $font-questrial;
  letter-spacing: 0.04em;
  text-align: center;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

@mixin animated-background {
  animation-duration: 3.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    colors.$gray-05 4%,
    #fafafa 18%,
    colors.$gray-05 33%
  );
  background-size: 600px 104px;
}

@mixin animated-image {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-90deg, #000 30%, #0005, #000 70%) right/600%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
