@use "src/styles/functions";
// import bootstrap
@use "src/styles/bootstrap/variables/colors";
@use "src/styles/bootstrap/variables/zIndex";
@use "src/styles/mixins";
@forward "./styles/bootstrap";
@forward "./styles/fonts.scss";
@forward "./styles/spacings.css";

:root {
  --pale-blue: #{colors.$pale-blue};
  --pale-orange: #ffe5da;
  --orange: #{colors.$orange};
  --deep-orange: #{colors.$deep-orange};
  --secondary-orange: #{colors.$secondary-orange};
  --background-orange: #{colors.$background-orange};
  --yellow: #ffd300;
  --dark-yellow: #f7bf59;
  --dandelion: #fcf3ce;
  --blue: #{colors.$primary};
  --bg-blue: #f3faff;
  --bg-orange: #ffe5da;
  --deep-blue: #185888;
  --secondary-blue: #{colors.$secondary-blue};
  --error-red: #{colors.$error-red};
  --red: #ef0c79;
  --verified-green: #{colors.$verified-green};
  --gentle-green: #{colors.$gentle-green};
  --teal: #11cdef;
  --cyan: #72dbbb;
  --white: #{colors.$white};
  --gray: #8898aa;
  --gray01: #{colors.$gray-01};
  --gray02: #{colors.$gray-02};
  --gray03: #{colors.$gray-03};
  --gray04: #{colors.$gray-04};
  --system-gray: #8e8e93;
  --light-gray: #a3a3a3;
  --light: #f4f7fb;
  --lighter: #e9ecef;
  --primary: #{colors.$primary};
  --secondary: #f4f5f7;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --dark: #4f4f4f;
  --default: #172b4d;
  --neutral: #fff;
  --darker: #{colors.$text-black};
  --tints: #007aff;
}

html {
  font-size: 16px;

  // customize scroll bar
  *::-webkit-scrollbar {
    width: functions.toRem(6);
    height: functions.toRem(6);
  }

  *::-webkit-scrollbar-track {
    // background: #eee;
    // margin-left: functions.toRem(15);
    // margin-right: functions.toRem(15);
  }

  *::-webkit-scrollbar-thumb {
    border-radius: functions.toRem(20);
    border: functions.toRem(3) solid rgba(189, 189, 189, 0.6);
  }

  // tl;dr needed for quill.js
  // Quill uses a div element with the contenteditable attribute applied to allow an end-user to edit the editor's content.
  // Safari has the user-select attribute set to none by default and this causes the issue in this web browser. To solve the issue, apply the following CSS style in your application:
  [contenteditable] {
    -webkit-user-select: text;
    user-select: text;
  }
}

@media only screen and (max-width: 767.98px) and (orientation: portrait) {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
}

body {
  color: var(--darker);
  background-color: white;
  overflow: auto;
  // used for is scrolling is happening in child element, there would be double scrollbar
  overflow: overlay;
}

.questrial {
  font-family: "Questrial", sans-serif !important;
}

.background-transparent {
  background-color: transparent !important;
}

.background-white {
  background-color: white;
}

.background-bg-blue {
  background-color: colors.$background-blue;
}

.background-green {
  background-color: #6fcf97;
}

.background-gray {
  background-color: colors.$gray-02;
}

.fw-500 {
  font-weight: 500 !important;
}

.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.secondary-orange {
  color: colors.$secondary-orange !important;
}

.primary {
  color: colors.$primary !important;
}

.white {
  color: white !important;
}

.blue {
  color: colors.$primary;
}

.deep-blue {
  color: colors.$deep-blue;
}

.red {
  color: colors.$error-red;
}

.orange {
  color: colors.$orange;
}

.deep-orange {
  color: colors.$deep-orange;
}

.dark {
  color: var(--dark);
}

.darker {
  color: var(--darker);
}

.green {
  color: var(--verified-green);
}

.gray01 {
  color: var(--gray01);
}

.gray02 {
  color: var(--gray02) !important;
}

.gray04 {
  color: var(--gray04);
}

.light-gray {
  color: var(--light-gray);
}

.line-through {
  text-decoration: line-through !important;
  text-decoration-thickness: functions.toRem(0.8) !important;
}

.z-500 {
  z-index: 500;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.required {
  color: colors.$red;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--default);
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  padding: 1.5rem;
  // border-radius: 0.25rem;
  background: white;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  animation: show-navbar-collapse 0.2s ease forwards;
}

@media (min-width: 991px) {
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
}

.table-flush td,
.table-flush th {
  border-left: 0;
  border-right: 0;
  font-size: 0.75rem;
  vertical-align: middle !important;
}

.table .thead-light th {
  color: var(--default);
  background-color: transparent;
  border-bottom: 1px solid var(--lighter);
  font-weight: 400 !important;
  font-size: 0.75rem;
}

.table thead th {
  border-top: none !important;
}

.table td,
.table th {
  border-color: var(--lighter);
}

.table tbody {
  border: 1px solid var(--lighter);
}

.table-responsive {
  overflow: hidden;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.fs-11 {
  font-size: 0.6875rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-15 {
  font-size: 0.9375rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

/*
 * flex
*/

ul.a {
  list-style-type: circle;
}

ul.no-bullets {
  list-style-type: none;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

.group-session-description h1,
.group-session-description h2 {
  margin-bottom: 1rem;
}

.circle {
  border-radius: 25px !important;
}

.text-success {
  color: var(--verified-green) !important;
}

.text-danger {
  color: var(--error-red) !important;
}

.shadow-sm {
  box-shadow: 0px 2px 4px rgba(74, 74, 74, 0.15) !important;
}

.shadow {
  box-shadow: 0px 2.5px 0px rgba(0, 0, 0, 0.13) !important;
}

.module {
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.p-btn {
  padding: 0.25rem 2.1rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: colors.$gray-04;
}

.disabled-link {
  pointer-events: none;
  color: var(--gray02);
  border-color: var(--gray02) !important;
}

.w-65 {
  max-width: 65%;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: colors.$gray-04;
  border: 1px solid colors.$gray-03;
}

.form-mobile .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0 !important;
  padding: 0 !important;
  border-bottom: 1px solid colors.$gray-02;
}

.form-mobile .form-control:focus {
  border-color: colors.$primary;
  box-shadow: none;
}

.form-mobile .custom-select {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--gray02);
}

.form-mobile .react-select .react-select__control {
  @include mixins.rg-14;
  border-bottom: functions.toRem(1) solid colors.$gray-02;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  min-height: functions.toRem(40);
  box-shadow: none;

  &:hover,
  &:focus {
    border-bottom: functions.toRem(1) solid colors.$primary;
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
  }
}

.StripeElement {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

#popover-session-type.popover {
  border-color: colors.$primary;
}

#popover-session-type.bs-popover-auto[x-placement^="right"] > .arrow::before,
#popover-session-type.bs-popover-right > .arrow::before {
  border-right-color: colors.$primary;
}

#popover-session-type .custom-select {
  border-color: colors.$primary;
}

input[type="date"]:invalid::-webkit-datetime-edit {
  color: colors.$gray-02;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield; /* Firefox */
}
