@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.wrapper {
  .body {
    padding: functions.toRem(8) functions.toRem(32);
  }
  .header {
    padding: functions.toRem(32);
  }
  .footer {
    padding: functions.toRem(16) functions.toRem(32) functions.toRem(32)
      functions.toRem(32);
    border: none;
    display: block;

    > button {
      margin: 0;
    }

    > div {
      margin: functions.toRem(24) 0 0 0;
    }
  }
}

.title {
  @include mixins.rg-24;
  color: colors.$deep-blue;
  font-family: "Questrial";
  margin-bottom: functions.toRem(24);
}

.password {
  & :global(.form-control) {
    border-radius: 0.25rem !important;
  }
  &:global(.input-group)
    :global(.input-group-append)
    :global(.input-group-text) {
    border: none !important;
    padding: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    background-color: transparent;
  }

  &:global(.input-group) :global(.input-group-append) {
    position: absolute;
    right: 0;
    z-index: 100;
  }

  & :global(.form-control.is-invalid),
  & :global(.form-control:invalid) {
    background-image: none;
  }

  & :global(.form-control.is-valid),
  & :global(.form-control:valid) {
    background-image: none;
  }
}

.login-button {
  @include mixins.rg-16;
  width: 100%;
}

.form-label {
  @include mixins.rg-12;
  color: colors.$gray-01;
}

.remember-me-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    @include mixins.rg-12;
    padding: 0;
  }
}

.register-row {
  @include mixins.rg-12;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: colors.$gray-01;

  button {
    @include mixins.rg-12;
    padding-top: 0;
    padding-bottom: 0;
  }
}

// for phone, TODO: cleanup

.login-modal {
  &__header {
    &--phone {
      @include mixins.bd-17;
      height: constants.$navbarHeight;
      padding: functions.toRem(14) functions.toRem(15);
      border-bottom: functions.toRem(1) solid var(--gray03);
      display: flex;
      align-items: center;
      justify-content: center;

      // the second child, name
      & > *:nth-child(2) {
        margin-left: 0 !important;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      // others
      & > *:not(:nth-child(2)) {
        flex: none;
      }
    }
  }

  &__password {
    & :global(.form-control) {
      border-radius: 0.25rem !important;
    }
    &:global(.input-group)
      :global(.input-group-append)
      :global(.input-group-text) {
      border: none !important;
      padding: 0;
      margin-right: 0.5rem;
      cursor: pointer;
      background-color: transparent;
    }

    &:global(.input-group) :global(.input-group-append) {
      position: absolute;
      right: 0;
      z-index: 100;
    }

    & :global(.form-control.is-invalid),
    & :global(.form-control:invalid) {
      background-image: none;
    }

    & :global(.form-control.is-valid),
    & :global(.form-control:valid) {
      background-image: none;
    }
    &--phone {
      & :global(.form-control) {
        border-radius: 0.5rem !important;
      }
    }
  }

  &__auth-form {
    &--phone {
      & :global(.form-control) {
        padding: functions.toRem(15) functions.toRem(16);
        border: functions.toRem(1) solid #e1e1e1;
        border-radius: functions.toRem(8);
        height: functions.toRem(52);
      }
    }
  }

  &__auth-button {
    &--phone {
      @include mixins.bd-15;
      border-radius: 0.5rem;
      padding: functions.toRem(14) functions.toRem(20);
    }
  }
}

.mfa-title {
  @include mixins.rg-32;
  font-family: "Questrial";
  color: colors.$deep-blue;
  text-align: center;
  margin-bottom: functions.toRem(8);
}

.mfa-description {
  @include mixins.rg-16;
  text-align: center;
}

.mfa-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: functions.toRem(24);
}
