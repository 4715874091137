@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";

.content-header {
  @include mixins.phone-sticky-top;
  @include mixins.bd-17;

  height: constants.$navbarHeightPhoneView;
  padding: functions.toRem(14) functions.toRem(15);
  border-bottom: functions.toRem(1) solid var(--gray03);
  display: flex;
  align-items: center;
  justify-content: center;

  // the second child, name
  & > *:nth-child(2) {
    margin-left: 0 !important;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // others
  & > *:not(:nth-child(2)) {
    flex: none;
  }

  &--hide-back-button {
    // the first child, name
    & > *:nth-child(1) {
      margin-left: 0 !important;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // others
    & > *:not(:nth-child(1)) {
      flex: none;
    }
  }
}
