button.icon-button {
  border: none;
  padding: 0;
  color: inherit;
  font-size: inherit;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}
