// customize bootstrap
// for the list of variables, see https://getcolors.com/docs/4.6/getting-started/theming/

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

:root {
  --gutter: 20px;
}

// The fact that we have to use a `max-width` media query here, so as to not
// overlap with the next media query, is a quirk of postcss-css-variables
@media (min-width: map-get($grid-breakpoints, "md")) {
  :root {
    --gutter: 24px;
  }
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
  :root {
    --gutter: 16px;
  }
}

@media (min-width: map-get($grid-breakpoints, "xl")) {
  :root {
    --gutter: 17px;
  }
}

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 335px,
  md: 720px,
  lg: 960px,
  // xl: 1166px,
  xl: 1240px,
);
