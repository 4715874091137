@use "styles/functions";
@use "styles/bootstrap/variables/colors";

.container {
  display: flex;
  gap: functions.toRem(8);
  height: functions.toRem(8);
}

.step {
  flex: 1;
  background-color: colors.$gray-03;
  border-radius: functions.toRem(8);

  &.active {
    background-color: colors.$brand-blue;
  }
}
