@use "src/styles/bootstrap/variables/colors";

.blog-cta {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  margin-bottom: 32px;

  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 24px;

  border-radius: 8px;

  box-shadow: 0px 2px 6px 0px #bdbdbd80;

  &__illustration {
    margin-left: -12px;
    align-self: center;
  }

  &__title {
    margin-top: -16px;
    margin-bottom: 16px;

    font-size: 18px;
    font-weight: 600;
    line-height: 28px;

    text-align: center;
  }

  &--phone {
    margin-bottom: 0;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 0px rgba(189, 189, 189, 0.5);
  }
}
