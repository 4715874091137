@use "src/styles/mixins";
@use "src/styles/functions";
@use "src/styles/constants";
@use "src/styles/bootstrap/variables/colors";

.item-list {
  padding: 56px 20px 16px 20px;
  flex-direction: column;
  display: flex;
  align-items: center;

  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    margin-bottom: 8px;
    text-align: center;
  }

  &__content {
    @include mixins.rg-16;
    text-align: center;
    color: colors.$text-black;
    margin-bottom: 32px;
  }

  &__card {
    box-shadow: 4px 4px 21px 0px rgba(168, 181, 202, 0.2);
    border-radius: 20px;
    padding: 32px;
    margin-bottom: 24px;
    border: none;
  }

  &__card-icon {
    margin-bottom: 24px;
  }

  &__card-title {
    @include mixins.bd-18;
    margin-bottom: 16px;
  }

  &__card-content {
    @include mixins.rg-16;
  }
}

.phone-view {
  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
    margin-bottom: 24px;
  }

  &__faq {
    padding: 56px 20px 16px 20px;
  }

  & :global(.card-header) {
    @include mixins.md-14;
    padding: functions.toRem(16) 0;
    height: auto;
  }

  & :global(.card-body) {
    @include mixins.rg-14;
    padding: functions.toRem(16) 0;
  }
}

.item {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  position: relative;

  border-radius: 8px;
  border: 1px solid colors.$gray-03;
  background-color: colors.$white;

  font-size: functions.toRem(14);
  line-height: functions.toRem(20);

  flex-basis: 100%;
  margin-bottom: 16px;

  &__body {
    padding-top: 36px;
    text-align: left;
  }

  &__footer {
    display: flex;
    gap: functions.toRem(8);

    color: colors.$gray-01;

    font-size: functions.toRem(12);
    line-height: functions.toRem(18);
  }
}

.quote-sign {
  position: absolute;
  left: 24px;
  top: 24px;

  color: colors.$primary;
  font-family: SeoulHangang CM;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 100% */
  letter-spacing: 1.2px;
}

.slogan {
  margin: 16px 20px 56px 20px;
  padding: 32px 24px;
  background-color: colors.$primary;

  border-radius: 20px;

  &__title {
    font-family: "Questrial";

    color: white;
    margin-bottom: 24px;

    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 131.25% */
  }

  &__content {
    @include mixins.rg-14;
    text-align: center;
    color: white;
  }
}

.action {
  margin-top: functions.toRem(24);
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.landing {
  background-color: colors.$background-blue;
  padding: 56px 20px 32px 20px;

  &__title {
    color: colors.$deep-blue;

    font-family: "Questrial";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0.8px;
    margin-bottom: 16px;
  }

  &__content {
    @include mixins.rg-16;
  }

  &__image {
    margin-top: 32px;
    margin-bottom: 8px;
  }
}

.start-trial {
  margin-bottom: functions.toRem(16);
  text-align: center;
}

.title {
  @include mixins.rg-24;
  color: colors.$deep-blue;
  font-family: "Questrial";
  text-align: center;
  margin-bottom: 16px;
}

.more-question {
  padding: 32px 24px;
  background-color: colors.$gray-03;
  margin: 0 20px;
  border-radius: 15px;

  &__title {
    @include mixins.rg-24;
    font-family: "Questrial";
    color: colors.$deep-blue;
  }

  &__action {
    width: 100%;
    margin-top: 24px;
  }
}

.commitment {
  background-color: colors.$deep-blue;
  padding: 32px 24px;
  border-radius: 20px;
  margin: 0 20px;

  &__title {
    @include mixins.rg-24;
    font-weight: 600;
    color: colors.$white;
    margin-bottom: 32px;
  }

  &__content {
    @include mixins.rg-16;
    color: colors.$white;
  }
}
