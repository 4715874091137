@use "src/styles/constants";
@use "src/styles/functions";
@use "src/styles/bootstrap/variables/zIndex";
@use "src/styles/bootstrap/variables/colors";

.nav-bar {
  /* control height */
  height: constants.$navbarHeight;
  flex: none;
  z-index: 1000;
  padding: functions.toRem(16) functions.toRem(20);

  &__left {
    padding-left: functions.toRem(50);

    &:global(.navbar-nav) :global(.nav-link.active) {
      border-bottom: functions.toRem(2) solid colors.$primary;
      font-weight: 600;
      padding: functions.toRem(16) 0;
    }

    &:global(.navbar-nav) :global(.nav-link) {
      padding: functions.toRem(16) 0;
      border-bottom: functions.toRem(2) solid transparent;
      margin-right: functions.toRem(32);
    }
  }
}

.navbar-fixed {
  min-width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: zIndex.$zindex-fixed;
}

@media (max-width: 1060px) {
  .nav-bar {
    padding: 0;

    & :global(.navbar-brand) {
      justify-content: center;
      width: 100%;
      height: constants.$navbarHeight;
    }
    & :global(.navbar-toggler) {
      display: block;
      right: functions.toRem(20);
      position: absolute;
      z-index: zIndex.$zindex-tooltip;
    }
  }
}
