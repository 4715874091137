@use "src/styles/functions";
@use "src/styles/mixins";

$borderRadius: functions.toRem(14);

.input-with-backdrop {
  &__modal {
    &:global(.show.fade) .input-with-backdrop__modal-dialog {
      transform: none;
    }

    &:global(.fade) .input-with-backdrop__modal-dialog {
      transition: transform 0.2s ease-out;
      transform: translate(0, 100%);
    }
  }

  &__modal-dialog {
    margin: 0;
    position: fixed !important;
    bottom: 0;
    width: 100vw !important;

    > .input-with-backdrop__modal-content {
      background-color: transparent;
      border: none;
      margin: functions.toRem(22);
      width: auto;

      // bootstrap fiix
      :global(.list-group-item) {
        @include mixins.rg-20;

        border-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--tints);

        &.input-with-backdrop__cancel {
          @include mixins.bd-20;
        }

        &:first-child {
          border-top-left-radius: $borderRadius;
          border-top-right-radius: $borderRadius;
        }
        &:last-child {
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }
      }
    }
  }

  &__cancel-container {
    margin-top: functions.toRem(12);
  }

  &__input:read-only {
    background-color: unset !important;
    opacity: unset;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
