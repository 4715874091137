@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/bootstrap/variables/colors";

.complete-intake-modal {
  &__content {
    & :global(.modal-header) {
      display: flex;
      align-items: center;
      border-bottom: functions.toRem(1) solid var(--gray03) !important;
    }

    &--phone {
      border-radius: 0 !important;

      & :global(.modal-header) {
        display: flex;
        align-items: center;
        border-bottom: functions.toRem(1) solid var(--gray03) !important;
      }

      & :global(.modal-title) {
        @include mixins.rg-17;
        color: colors.$black;
        font-family: "Noto Sans", sans-serif;
      }
    }
  }

  &__header {
    padding: functions.toRem(16) !important;
    &--phone {
      display: flex;
      align-items: center;
      padding: functions.toRem(16) !important;
    }
  }

  &__body {
    padding: 0 functions.toRem(16) !important;
  }
}
