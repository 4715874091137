@use "src/styles/bootstrap/variables/colors";

.reading-progress-bar {
  position: fixed;

  top: 56px;
  left: 0;
  right: 0;

  height: 4px;

  background-color: colors.$background-orange;

  transition: all 1s;

  z-index: 1;

  &--ignore-header {
    top: 0;
  }

  &--phone {
    transition: none;
  }

  &--hidden {
    top: 0;
    height: 0;
    opacity: 0;
    transition: none;
  }

  .indicator {
    position: absolute;

    top: 0;
    left: 0;

    height: 4px;

    background-color: colors.$deep-orange;
  }
}
