@use "src/styles/functions";
@use "src/styles/mixins";
@use "src/styles/constants";
@use "./styles/bootstrap/variables/colors";

.faq {
  padding: 120px 0 0 0;
  display: flex;
  flex-direction: row;
  gap: 32px;
  max-width: 1376px;

  &__title {
    @include mixins.rg-40;
    color: colors.$deep-blue;
    font-family: "Questrial";
    margin-bottom: 56px;
    padding-left: 24px;
  }

  &__sticky-column-content {
    position: sticky;
    top: functions.toRem(98);
  }

  &__side-column {
    flex-basis: 250px;
  }

  &__main-column {
    flex-basis: 812px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__action {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 100px;

    a {
      padding: 8px 16px;
    }
  }
}

.slogan {
  padding: functions.toRem(64) 0;
  background-color: colors.$primary;
  margin-top: functions.toRem(56);
  margin-bottom: functions.toRem(120);
  border-radius: functions.toRem(24);
  max-width: 1376px;

  &__title {
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    color: white;
    margin-bottom: 24px;
    text-align: center;
  }

  &__content {
    @include mixins.rg-16;
    text-align: center;
    color: white;
  }
}

.action {
  margin-top: functions.toRem(32);
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: colors.$deep-orange;
  background-color: colors.$deep-orange;
  box-shadow: none;
  padding: 8px 16px;

  &:focus,
  &:hover {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: colors.$deep-orange;
    background-color: colors.$deep-orange;
    box-shadow: none !important;
  }
}

.landing {
  background-color: #caddfd;
  display: flex;
  justify-content: space-between;
  height: 750px;
  position: relative;

  &__container {
    max-width: 1376px;
    padding: 0;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__title {
    color: colors.$deep-blue;
    font-family: "Questrial";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 124% */
    letter-spacing: 2px;
    margin-bottom: 16px;
  }

  &__text {
    @include mixins.rg-18;
  }
}

.list-container {
  height: 100%;
  background-color: transparentize($color: colors.$text-black, $amount: 0.4);

  .title {
    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 12px;
  }

  &__content {
    background-color: white;
  }

  &__ul {
    padding: 0;

    li {
      @include mixins.rg-14;
      list-style: none;

      padding-top: 8px;
      padding-bottom: 8px;
      color: colors.$gray-01;

      &.highlighted {
        a {
          color: colors.$deep-orange;
        }
      }
      &:hover {
        cursor: pointer;
      }

      a {
        display: block;
        color: colors.$gray-01;
        text-decoration: none;
        font-size: functions.toRem(12);
        font-weight: 400;
        line-height: functions.toRem(24);
      }
    }
  }
}

.title {
  margin-bottom: 24px;

  font-size: functions.toRem(14);
  font-weight: 600;
  line-height: functions.toRem(28);

  text-transform: uppercase;
}

.phone-view {
  &__title {
    @include mixins.rg-24;
    color: colors.$deep-blue;
    font-family: "Questrial";
    text-align: center;
  }

  &__faq {
    padding: 32px 20px 0 20px;

    > .faq__title {
      @include mixins.rg-24;
      color: colors.$deep-blue;
      font-family: "Questrial";
      text-align: center;
      margin-bottom: 0;
      padding: 0;

      &:not(:first-child) {
        margin-top: 56px;
      }
    }

    > .faq__action {
      margin-bottom: 0 !important;

      a {
        width: 100%;
      }
    }
  }

  &__faq-cta {
    margin-top: 32px;
    padding: 0 20px;
  }

  & :global(.card-header) {
    @include mixins.md-14;
    padding: functions.toRem(16) functions.toRem(16) functions.toRem(16)
      functions.toRem(20);
    height: auto;
  }

  & :global(.card-body) {
    @include mixins.rg-14;
    padding: functions.toRem(16) functions.toRem(20);
  }
}

.slogan-phone {
  padding: 32px 24px;
  background-color: colors.$primary;
  margin: 32px 20px;
  border-radius: 20px;

  &__title {
    font-family: "Questrial";

    color: white;
    margin-bottom: 24px;

    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 131.25% */
  }

  &__content {
    @include mixins.rg-14;
    text-align: center;
    color: white;
  }
}

.landing-phone {
  margin-top: -52px; // For Table of Contents

  background-color: #caddfd;
  padding: 56px 20px;

  &__title {
    color: colors.$deep-blue;

    font-family: "Questrial";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0.8px;
    margin-bottom: 16px;
  }

  &__content {
    @include mixins.rg-16;
    color: white;
  }

  &__image {
    margin-top: 32px;
    margin-bottom: 8px;
  }
}
